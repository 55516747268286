import React, { useEffect, useRef, useState } from 'react';
import Layout from '../elements/Layout';
import logo from '../assets/miles_logo.svg';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { IoCallSharp } from 'react-icons/io5';
import { MdOutlineMailOutline } from 'react-icons/md';
import { IoIosGlobe } from 'react-icons/io';
import sign from '../assets/General_Signature_4.png';
import { FaDownload, FaLocationDot } from 'react-icons/fa6';
import { useParams } from 'react-router-dom/dist';
import toast from 'react-hot-toast';
import { checkPaymentStatus } from '../utils/api/api';
import Loading from '../elements/Loading';
import moment from 'moment/moment';
import { ToWords } from 'to-words';
import html2pdf from 'html2pdf.js';

const Receipt = () => {
    const [payment, setPayment] = useState([]);
    const [amountInWords, setAmountInWords] = useState('');
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const toWords = new ToWords({
        localeCode: 'en-GB',
    });
    const componentRef = useRef();

    const getPayment = async () => {
        setLoading(true);
        try {
            const response = await checkPaymentStatus(id);
            setPayment(response.payment);
        } catch (error) {
            toast.error(error.message || "Failed to get transaction");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPayment();
    }, []);

    useEffect(() => {
        if (payment?.amount) {
            const words = toWords.convert(payment.amount);
            setAmountInWords(words);
        }
    }, [payment?.amount]);

    const downloadPdf = () => {
        const element = componentRef.current;
        const opt = {
            margin: 0.5,
            filename: 'receipt.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        html2pdf().from(element).set(opt).save();
    };


    return (
        <Layout>
            {loading && <Loading />}
            <div className="w-full items-end flex justify-end gap-4 mb-4">
                <button
                    onClick={downloadPdf}
                    className="px-6 py-1.5 text-white bg-secondary rounded-md text-[14px] flex items-center justify-center gap-2 font-medium"
                >
                    <FaDownload />
                    Download
                </button>
            </div>
            {!loading && (
                <div ref={componentRef} className="bg-white max-w-6xl p-6 rounded-lg mx-auto">
                    <div className="border-4 flex items-center justify-between p-8 border-primary mb-4">
                        <div className="flex items-center gap-3">
                            <img src={logo} className='h-auto w-[150px]' alt="" />
                            <div className="flex flex-col">
                                <p className="uppercase text-primary font-semibold text-[20px]">milescoop ventures ltd</p>
                                <p className="text-gray-600 text-[15px] uppercase">p.o. box. 2760 - 00100 nairobi</p>
                                <p className="text-gray-600 text-[15px]">phone: +254 (0) 737 900 424</p>
                                <p className="text-gray-600 text-[15px]">Email: info@milescoop.com</p>
                                <p className="text-gray-600 text-[15px]">PIN NO: 052203487E</p>
                                <p className="text-gray-600 text-[15px]">Website: www.milescoop.com</p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <p className="px-8 uppercase text-primary font-semibold text-[20px]">payment method</p>
                            <div className='bg-primary h-[4px]' />
                            <div className="flex items-center justify-between w-full py-2">
                                <p className="">Date</p>
                                <p className="">{moment(payment?.createdAt).format("MMM DD, YYYY")}</p>
                            </div>
                            <div className='bg-primary h-[3px]' />
                            <div className="flex items-center justify-between w-full py-2">
                                <p className="">TRX Id.</p>
                                <p className="">{payment.transaction_id}</p>
                            </div>
                            <div className='bg-primary h-[3px]' />
                            <div className="flex items-center justify-between w-full py-2">
                                <p className="">Status</p>
                                <p className="">{payment.status}</p>
                            </div>
                            <div className='bg-primary h-[3px]' />
                        </div>
                    </div>
                    <div className='bg-primary h-[3px]' />
                    <div className="flex items-center mt-4">
                        <p className="w-[150px] text-black text-[16px]">
                            Received from:
                        </p>
                        <p className="font-semibold uppercase text-primary text-[18px]">{payment?.customer?.first_name} {payment?.customer?.last_name}</p>
                    </div>
                    <div className="flex items-center w-full justify-between mt-4">
                        <div className="flex items-center">
                            <p className="w-[150px] text-black text-[16px]">
                                Currency:
                            </p>
                            <p className="font-semibold uppercase text-gray-700 text-[15px] border border-gray-500 rounded-md px-4 py-1.5">kes</p>
                        </div>
                        <div className="flex items-center">
                            <p className="w-[100px] text-black text-[16px]">
                                Amount:
                            </p>
                            <p className="font-semibold uppercase text-gray-700 text-[15px] border border-gray-500 rounded-md px-4 py-1.5">{Number(payment?.amount).toLocaleString("KES")}</p>
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <p className="w-[150px] text-black text-[16px]">
                            Amt in words:
                        </p>
                        <p className="w-full text-end font-semibold text-gray-700 text-[15px] border border-gray-500 rounded-md px-4 py-1.5">
                            {amountInWords} shillings only
                        </p>
                    </div>
                    <div className="border-2 border-primary mt-4 p-4 flex flex-col rounded-md">
                        <p className="text-primary text-[16px] font-medium">Remarks</p>
                        <p className="text-gray-700 text-[14px]">
                            Amount KES {Number(payment?.amount).toLocaleString("KES")} received from
                            <span className="px-2 text-primary font-medium uppercase">{payment?.customer?.first_name} {payment?.customer?.last_name}</span>
                            transaction reference number
                            <span className="px-2 text-primary font-medium">{payment.transaction_id}</span>
                            dated
                            <span className="px-2 text-primary font-medium">{moment(payment?.createdAt).format("MMM DD, YYYY")}</span>
                        </p>
                    </div>
                    <div className="border-2 border-primary mt-4 p-4 flex flex-col rounded-md">
                        <p className="text-primary text-[16px] font-medium mb-2">Receipt type: ({payment?.method})</p>
                        <div className="flex iteme-center justify-between w-full">
                            <p className="">Transaction Id: {payment.transaction_id}</p>
                            <p className="">Date & time: {moment(payment?.createdAt).format("MMM DD, YYYY, HH:mm A")}</p>
                        </div>
                    </div>
                    <div className="flex items-center w-full mt-4 justify-between px-8">
                        <Barcode
                            value={"JJKKD8892jko2"}
                            width={2}
                            height={100}
                            displayValue={true}
                            background="#ffffff"
                            lineColor="#000000"
                            margin={10}
                            fontOptions="monospace"
                            textAlign="center"
                        />
                        <QRCode
                            value={"JJKKD8892jko2"}
                            size={100}
                            bgColor="#ffffff"
                            fgColor="#000000"
                        />
                    </div>
                    <footer className="mt-6">
                        <div className="flex justify-between text-gray-600 text-[14px]">
                            <div className="flex flex-col gap-2">
                                <p className="flex items-center gap-2">
                                    <span className="bg-secondary text-white rounded-full p-1.5"><IoCallSharp /></span>
                                    +254 (0) 737 900 424
                                </p>
                                <p className="flex items-center gap-2">
                                    <span className="bg-secondary text-white rounded-full p-1.5"><MdOutlineMailOutline /></span>
                                    info@milescoop.com
                                </p>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <p className="flex items-center gap-2">
                                    <span className="bg-secondary text-white rounded-full p-1.5"><IoIosGlobe /></span>
                                    www.milescoop.com
                                </p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="flex items-center gap-2">
                                    <span className="bg-secondary text-white rounded-full p-1.5"><FaLocationDot /></span>
                                    Design Centre, 4th floor, Room 4A
                                </p>
                                <p className="flex items-center gap-2">
                                    <span className="bg-secondary text-white rounded-full p-1.5"><FaLocationDot /></span>
                                    PIN NUMBER: 052203487E
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
            )}
        </Layout>
    )
}

export default Receipt