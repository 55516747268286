import React, {  } from 'react';
import {
    MdOutlineDashboard,
    MdAttachMoney,
    MdAssignment,
    MdMessage,
    MdSupportAgent,
    MdSettings,
    MdLogout,
    MdWorkOutline,
    MdPayment,
    MdReceipt,
    MdPersonAddAlt,
    MdEvent
} from 'react-icons/md';
import { LuTableProperties } from "react-icons/lu";
import { Link, useLocation, useParams } from 'react-router-dom';
import { removeAccessToken } from '../utils/api/authToken';

const SideMenu = () => {
    const location = useLocation();
    const { id } = useParams();

    const isActive = (paths) => paths.includes(location.pathname)
        ? 'bg-secondary text-primary'
        : 'text-white';

    return (
        <div className='bg-primary h-full w-full text-white pr-8 hidden md:flex py-8 flex-col overflow-y-auto'>
            <p className="border-b mb-3 border-gray-500 text-white text-[15px] pb-2">General</p>

            <Link to={`/dashboard`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard', '/dashboard/statement'])}`}>
                <MdOutlineDashboard />
                <span className='flex text-[13px]'>Dashboard</span>
            </Link>

            <Link to={`/dashboard/orders`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full  ${isActive(['/dashboard/orders', `/dashboard/orders/${id}/view`])}`}>
                <MdAttachMoney />
                <span className='flex text-[13px]'>Pay now</span>
            </Link>

            <Link to={`/dashboard/projects`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/projects', `/dashboard/projects/${id}/view`, `/dashboard/projects/${id}/payment`])}`}>
                <MdWorkOutline />
                <span className='flex text-[13px]'>Projects</span>
            </Link>

            <Link to={`/dashboard/my-properties`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-800 hover:text-white ${isActive(['/dashboard/my-properties'])}`}>
                <LuTableProperties />
                <span className='flex text-[13px]'>My Properties</span>
            </Link>

            <Link to={`/dashboard/contracts`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/contracts', `/dashboard/contracts/${id}/view-details`])}`}>
                <MdAssignment />
                <span className='flex text-[13px]'>Contracts</span>
            </Link>

            <Link to={`/dashboard/invoices`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/invoices', `/dashboard/invoices/${id}/view`])}`}>
                <MdReceipt />
                <span className='flex text-[13px]'>Invoices</span>
            </Link>

            <Link to={`/dashboard/payments`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full  ${isActive(['/dashboard/payments', `/dashboard/payments/${id}/view-details`])}`}>
                <MdPayment />
                <span className='flex text-[13px]'>My Statement</span>
            </Link>            

            <Link to={`/dashboard/bookings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/bookings'])}`}>
                <MdEvent />
                <span className='flex text-[13px]'>Bookings</span>
            </Link>

            <Link to={`/dashboard/messaging`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/messaging'])}`}>
                <MdMessage />
                <span className='flex text-[13px]'>Messaging</span>
            </Link>

            <Link to={`/dashboard/support`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/support'])}`}>
                <MdSupportAgent />
                <span className='flex text-[13px]'>Support</span>
            </Link>

            <Link to={`/dashboard/referral`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/referral'])}`}>
                <MdPersonAddAlt />
                <span className='flex text-[13px]'>Referral</span>
            </Link>

            <Link to={`/dashboard/settings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/settings'])}`}>
                <MdSettings />
                <span className='flex text-[13px]'>Account</span>
            </Link>

            <button onClick={removeAccessToken} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[13px]  hover:bg-secondary hover:text-white ${isActive(['/dashboard/logout'])}`}>
                <MdLogout />
                <span className='flex text-[13px]'>Log Out</span>
            </button>
        </div>
    );
}

export default SideMenu;
