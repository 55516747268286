import api from "./axiosInstance";

// tickets
export const fetchTickets = async () => {
  try {
    const response = await api.post('/tickets/list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerTickets = async (customer_id) => {
  try {
    const response = await api.post('/tickets/list-customer', { customer_id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTicket = async (ticketData) => {
  try {
    const response = await api.post('/tickets/create', ticketData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// contracts
export const fetchCustomerContracts = async (data) => {
  try {
    const response = await api.post('/contracts/list-customer', data);
      return response.data;
  } catch (error) {
    throw error; 
  }
}

export const fetchSingleContract = async (id) => {
  try {
    const response = await api.post('/contracts/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

// bookings
export const fetchBookings = async () => {
  try {
    const response = await api.post('/bookings/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const acceptBooking = async (bookingData) => {
  try {
    const response = await api.post('/bookings/accept', bookingData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createCustomBooking = async (bookingData) => {
  try {
    const response = await api.post('/bookings/create-custom', bookingData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerBookings = async (customer_id) => {
  try {
    const response = await api.post('/bookings/list-customer', {customer_id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProjects = async () => {
  try {
    const response = await api.post('/projects/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProjectById = async (id) => {
  try {
    const response = await api.post('/projects/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerInvoices = async (data) => {
  try {
    const response = await api.post('/invoices/list-customer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchInvoice = async (id) => {
  try {
    const response = await api.post('/invoices/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerStats = async (customer_id) => {
  try {
    const response = await api.post('/analytics/clientStats', {customer_id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerProperties = async (customer_id) => {
  try {
    const response = await api.post('/property/list-customer', {customer_id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProperty = async (id) => {
  try {
    const response = await api.post('/property/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerOrders = async (data) => {
  try {
    const response = await api.post('/orders/list-customer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProperties = async (data) => {
  try {
    const response = await api.post('/property/list', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createOrder = async (orderData) => {
  try {
    const response = await api.post('/orders/create', orderData)
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchOrder = async (id) => {
  try {
    const response = await api.post('/orders/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// payments
export const processMpesaPayment = async (paymentData) => {
  try {
    const response = await api.post('/payments/kcb', paymentData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const processCardPayment = async (paymentData) => {
  try {
    const response = await api.post('/payments/card', paymentData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitReferral = async (leadData) => {
  try {
    const response = await api.post('/leads/create', leadData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerById = async (id) => {
  try {
    const response = await api.post('/customers/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerDocs = async (customer_id) => {
  try {
    const response = await api.post('/customers/get-docs', { customer_id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (editCustomerData) => {
  try {
    const response = await api.post('/customers/update', editCustomerData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const checkPaymentStatus = async (id) => {
  try {
    const response = await api.post('/payments/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getCustomerPayments = async (data) => {
  try {
    const response = await api.post('/payments/list-customer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerWallet = async (customer_id) => {
  try {
    const response = await api.post('/customers/get-wallet', {customer_id});
    return response.data;
  } catch (error) {
    throw error
  }
}

export const payFromWallet = async (data) => {
  try {
   const response = await api.post('/customers/get-wallet', data);
    return response.data;
  } catch (error) {
    throw error
  }
}

export const getCustomerStatements = async (customer_id) => {
  try {
    const response = await api.post('/customers/get-statement', { customer_id });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const processWalletPayment = async (data) => {
  try {
    const response = await api.post('/payments/pay-from-wallet', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
