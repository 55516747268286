import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { getCustomerPayments } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import moment from 'moment';
import { FaEye } from 'react-icons/fa6';
import Loading from '../elements/Loading'
import { Link } from 'react-router-dom';

const Payments = () => {
  const cu_id = Cookies.get('user_id');
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const getPayments = async (page, pageSize) => {
    setLoading(true);
    const payload = { customer_id: Number(cu_id), page, pageSize };
    try {
      const response = await getCustomerPayments(payload);
      setPayments(response.payments);
      setTotalPages(response.totalPages);
    } catch (error) {
      toast.error(error.message || "Failed to fetch payments");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayments(page, pageSize);
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  return (
    <Layout text="Payments">
      <div className="w-full md:bg-white md:rounded-lg md:shadow-lg md:p-6">
        <div className="flex flex-col md:flex-row gap-2 justify-between w-full mb-4">
          <div>
            <label htmlFor="pageSize" className="text-sm text-gray-600 mr-2">Items per page:</label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={handlePageSizeChange}
              className="border border-gray-300 rounded-md text-sm py-1.5 px-3 focus:ring-secondary focus:border-secondary"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>

        <div id="paymentsTable" className="hidden md:block overflow-x-auto">
          <table className="min-w-full bg-white table-auto border border-gray-200 rounded-lg">
            <thead className="bg-[#F7F7F7] text-gray-700 uppercase text-sm font-medium">
              <tr>
                <th className="py-3 px-6 text-left text-[13px]">TRX No.</th>
                <th className="py-3 px-6 text-left text-[13px]">Transaction ID</th>
                <th className="py-3 px-6 text-left text-[13px]">Amount</th>
                <th className="py-3 px-6 text-left text-[13px]">Method</th>
                <th className="py-3 px-6 text-left text-[13px]">Date</th>
                <th className="py-3 px-6 text-left text-[13px]">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-[14px]">
              {loading ? (
                <tr>
                  <td colSpan="8" className="py-4 text-center">Loading...</td>
                </tr>
              ) : payments.length > 0 ? (
                payments.map((payment) => (
                  <tr key={payment.id} className="text-[13px] border-b border-gray-200 hover:bg-gray-50 transition duration-150">
                    <td className="py-3 px-6 text-left">{payment.transaction_number}</td>
                    <td className="py-3 px-6 text-left">{payment.transaction_id}</td>
                    <td className="py-3 px-6 text-left">KES {Number(payment.amount).toLocaleString("KES")}</td>
                    <td className="py-3 px-6 text-left">{payment.method}</td>
                    <td className="py-3 px-6 text-left">{moment(payment.createdAt).format('MMM Do, YYYY, h:mm A')}</td>
                    <td className="py-3 px-6 text-left">
                      <Link
                        className='text-secondary hover:text-secondary-dark transition duration-150'
                        to={`/dashboard/payments/${payment.id}/view-details`}
                      >
                        <FaEye size={24} />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="py-4 text-center text-gray-600">No payments found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          {loading ? (
            <Loading />
          ) : payments.length > 0 ? (
            payments.map((payment, index) => (
              <Link to={`/dashboard/payments/${payment.id}/view-details`} key={payment.id} className="flex border bg-white p-3 mt-2 rounded-lg  items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="bg-green-500 w-[40px] flex items-center justify-center h-[40px] text-white p-2 rounded-full">{index + 1}</div>
                  <div className="flex flex-col">
                    <p className="text-[14px] text-primary">{payment.transaction_id.slice(0, 7)}</p>
                    <p className="text-[11px] text-gray-600 ">{payment.method}</p>
                  </div>
                </div>
                <div className="flex flex-col text-end">
                  <p className="font-medium text-primary text-[13px]">KES {Number(payment.amount).toLocaleString()}</p>
                  <p className="text-gray-500 text-[11px] ">{moment(payment.createdAt).format('MMM Do, YYYY, h:mm A')}</p>
                </div>
              </Link>
            ))
          ) : (
            <div className="text-center text-gray-600">No payments found.</div>
          )}
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="text-sm text-gray-600">
            Page {page} of {totalPages}
          </div>
          <div className="flex items-center gap-2">
            <button
              className={`px-4 py-2 rounded-md text-sm ${page === 1 ? 'bg-gray-200 text-gray-500' : 'bg-gray-100 hover:bg-gray-200'}`}
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`px-4 py-2 rounded-md text-sm ${page === totalPages ? 'bg-gray-200 text-gray-500' : 'bg-gray-100 hover:bg-gray-200'}`}
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Payments;
