import React, { useState } from 'react';
import ImageSliderModal from './ImageSliderModal';
import { IoExpand } from "react-icons/io5";

const ProjecImages = ({ images }) => {
    const [showSlider, setShowSlider] = useState(false);

    if (!images || images.length === 0) return null;

    const handleOpenSlider = () => {
        setShowSlider(true);
    };

    return (
        <div className="w-full mt-6 gap-4 flex">
            <div className="flex w-full md:w-[70%] ">
                {images.length > 0 && (
                    <div className="relative h-full w-full">
                        <img
                            src={images[0].file}
                            alt="Image"
                            className="w-full h-[300px] object-cover rounded-lg cursor-pointer"
                            style={{ objectPosition: 'center' }}
                            onClick={handleOpenSlider}
                        />
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                            onClick={handleOpenSlider}
                        >
                            <IoExpand className="text-white" size={64} />
                        </div>
                    </div>
                )}
            </div>
            <div className="w-[30%] grid grid-cols-2 gap-2">
                {images.slice(0, 4).map((image, index) => (
                    <div key={index} className="w-fit h-auto">
                        <img
                            src={image.file}
                            alt={`Image ${index + 2}`}
                            className="w-[200px] h-auto object-cover rounded-lg"
                        />
                    </div>
                ))}
            </div>

            {showSlider && (
                <ImageSliderModal
                    images={images}
                    onClose={() => setShowSlider(false)}
                />
            )}
        </div>
    );
};

export default ProjecImages;
