import React, { useState } from 'react';

function Onboarding({ onComplete }) {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      onComplete();
    }
  };

  return (
    <div className="h-screen bg-gradient-to-br from-blue-900 via-purple-700 to-indigo-900 flex items-center justify-center text-white">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg text-center text-gray-800 transition duration-500">
        {step === 1 && (
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-indigo-900">Welcome to Milescoop</h1>
            <p className="text-gray-600">
              Your trusted platform for buying and selling land properties with ease and confidence.
            </p>
            {/* <img src="/images/onboarding-step1.png" alt="Welcome" className="mx-auto w-48" /> */}
          </div>
        )}

        {step === 2 && (
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-indigo-900">Explore Properties</h1>
            <p className="text-gray-600">
              Discover a range of properties tailored to meet your preferences and investment goals.
            </p>
            {/* <img src="/images/onboarding-step2.png" alt="Explore Properties" className="mx-auto w-48" /> */}
          </div>
        )}

        {step === 3 && (
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-indigo-900">Seamless Transactions</h1>
            <p className="text-gray-600">
              Experience secure, fast, and transparent payment processes backed by leading fintech solutions.
            </p>
            {/* <img src="/images/onboarding-step3.png" alt="Seamless Transactions" className="mx-auto w-48" /> */}
          </div>
        )}

        <button
          onClick={nextStep}
          className="mt-6 px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
        >
          {step < 3 ? 'Next' : 'Get Started'}
        </button>
      </div>
    </div>
  );
}

export default Onboarding;
