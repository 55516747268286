import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { fetchCustomerOrders, fetchCustomerWallet, getCustomerPayments, getCustomerStatements, processWalletPayment } from '../utils/api/api';
import toast from 'react-hot-toast';
import Layout from '../elements/Layout';
import Modal from '../elements/Modal';
import logo from '../assets/miles_logo.svg';
import Loading from '../elements/Loading';
import { Link } from 'react-router-dom';

const WalletStatement = () => {
    const customer_id = Cookies.get('user_id');
    const statementRef = useRef(null);
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statements, setStatements] = useState([]);
    const [payments, setPayments] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const getPayments = async () => {
        try {
            const response = await getCustomerPayments({
                customer_id,
                page: 1,
                pageSize: 1000,
            });
            setPayments(response.payments);
        } catch (error) {
            toast.error(error.message || "Failed to get payments");
        }
    };

    useEffect(() => {
        const getOrders = async () => {
            try {
                const response = await fetchCustomerOrders({
                    customer_id: customer_id,
                    page: 1,
                    pageSize: 1000,
                });
                setOrders(response.orders);
            } catch (error) {
                console.error("Failed to fetch orders", error);
            }
        };
        getOrders();
    }, []);

    const fetchCustomerStatements = async () => {
        try {
            const response = await getCustomerStatements(customer_id);
            setStatements(response);
        } catch (error) {
            toast.error("Failed to get statements");
        }
    };

    const getWallet = async () => {
        try {
            const response = await fetchCustomerWallet(customer_id);
            setWallet(response.wallet);
            setLoading(false);
        } catch (error) {
            toast.error(error.message || "Request failed");
            setLoading(false);
        }
    };

    const handleWalletPayment = async () => {
        if (!selectedOrder) {
            toast.error("Please select an order to pay from the wallet");
            return;
        }
        try {
            await processWalletPayment({
                customer_id,
                order_id: selectedOrder.id,
            });
            toast.success("Payment processed successfully from wallet");
            getPayments();
            window.location.reload();
        } catch (error) {
            toast.error(error.message || "Payment failed");
        }
    };

    useEffect(() => {
        getWallet();
        getPayments();
        fetchCustomerStatements();
    }, []);

    const handleOrderSelection = (orderId) => {
        const order = orders.find((order) => order.id === Number(orderId));
        setSelectedOrder(order);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-KE', {
            style: 'currency',
            currency: 'KES',
        }).format(amount);
    };

    if (loading) return <Loading />;

    const colorMap = {
        total_overdue: 'bg-red-100 text-red-600',
        total_due: 'bg-orange-100 text-orange-600',
        total_paid: 'bg-green-100 text-green-600',
        next_due: 'bg-yellow-100 text-yellow-600'
    };

    const labelMap = {
        total_overdue: 'Total Arrears',
        total_due: 'Outstanding Balance',
        total_paid: 'Total Paid',
        next_due: 'Upcoming'
    };

    return (
       <Layout>
            <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="flex flex-col p-3 md:p-12 mx-auto w-full" ref={statementRef}>
                    <div className="flex items-center justify-between">
                        <img src={logo} className="w-[150px] mb-6" alt="Logo" />
                        <div className="space-y-1 text-right text-gray-600">
                            <p className="text-[20px] font-semibold text-primary">Milescoop Ventures Ltd</p>
                            <p>P.O. Box 2760 - 00100, Nairobi, Kenya</p>
                            <p>+254 (0) 727 900 424</p>
                            <p>info@milescoop.com</p>
                            <p>www.milescoop.com</p>
                        </div>
                    </div>

                    <h1 className="w-full my-6 font-bold text-black text-2lg">Full Wallet Statement</h1>
                    <div className="grid grid-cols-1 gap-3 p-3 bg-white rounded-lg shadow-lg sm:grid-cols-4">
                        <div className={`p-4 text-center rounded-lg w-full ${colorMap.next_due}`}>
                            <p className="text-[12px] font-semibold">{labelMap.next_due} ({moment(statements?.next_due?.payment_due_date).format('MMM DD, YYYY')})</p>
                            <p className="mt-2 text-[14px] font-bold">{statements?.next_due !== null ? formatCurrency(statements?.next_due?.payment_status === 'partially_paid' ? statements?.next_due?.remaining_balance : statements?.next_due?.total_amount) : '--'}</p>
                        </div>
                        <div className={`p-4 text-center rounded-lg ${colorMap.total_overdue}`}>
                            <p className="text-[12px] font-semibold">{labelMap.total_overdue}</p>
                            <p className="mt-2 text-[14px] font-bold">{statements?.total_overdue !== null ? formatCurrency(statements?.total_overdue) : '--'}</p>
                        </div>

                        <div className={`p-4 text-center rounded-lg ${colorMap.total_paid}`}>
                            <p className="text-[12px] font-semibold">{labelMap.total_paid}</p>
                            <p className="mt-2 text-[14px] font-bold">{statements?.total_due !== null ? formatCurrency(statements?.total_paid) : '--'}</p>
                        </div>

                        <div className={`p-4 text-center rounded-lg ${colorMap.total_due}`}>
                            <p className="text-[12px] font-semibold">{labelMap.total_due}</p>
                            <p className="mt-2 text-[14px] font-bold">{statements?.total_due !== null ? formatCurrency(statements?.total_due) : '--'}</p>
                        </div>
                    </div>

                    {wallet?.balance > 0 && (
                        <div className="my-6 p-6 rounded-xl shadow-xl bg-gradient-to-r from-blue-100 to-blue-300">
                            <div className="flex items-center justify-between mb-4">
                                <p className="text-2xl font-semibold text-gray-900">Next invoice is less: </p>
                                <p className="text-xl font-medium text-blue-600">{formatCurrency(wallet.balance)}</p>
                            </div>
                            <button
                                onClick={() => setShowModal(true)}
                                className="w-full py-3 mt-6 text-lg font-semibold text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 rounded-lg shadow-md transform transition-transform duration-200 hover:scale-105"
                            >
                                Pay for invoice
                            </button>
                        </div>
                    )}

                    <div className="">
                        {loading ? (
                            <p className="">Loading</p>
                        ) : payments.length > 0 ? (
                            payments.map((payment, index) => (
                                <Link to={`/dashboard/payments/${payment.id}/view-details`} key={payment.id} className="flex items-center justify-between gap-6 p-3 mt-2 bg-white border rounded-lg">
                                    <div className="flex items-center gap-2">
                                        <div className="bg-green-500 w-[40px] flex items-center justify-center h-[40px] text-white p-2 rounded-full">{index + 1}</div>
                                        <div className="flex flex-col">
                                            <p className="text-[14px] text-primary truncate w-[120px] md:w-full">{payment.transaction_id}</p>
                                            <p className="text-[11px] text-gray-600 ">{payment.method}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col text-end">
                                        <p className="font-medium text-primary text-[13px]">KES {Number(payment.amount).toLocaleString()}</p>
                                        <p className="text-gray-500 text-[11px] ">{moment(payment.createdAt).format('MMM Do, YYYY, hh:mm')}</p>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <div className="text-center text-gray-600">No payments found.</div>
                        )}
                    </div>
                </div>
                {showModal && (
                    <Modal title="Pay for next invoice" onClose={() => setShowModal(false)}>
                        <h3 className="text-lg font-semibold mb-4">Select an Offer to Pay</h3>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-medium mb-2">Choose Order:</label>
                            <select
                                onChange={(e) => handleOrderSelection(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg outline-none focus:border-secondary bg-transparent"
                                value={selectedOrder?.id || ''}
                            >
                                <option value="" disabled>Select an order</option>
                                {orders.map((order) => {
                                    const projectTitle = order.project?.title || 'Unknown Project';
                                    const propertyDetails = order.order_items
                                        .map((item) => `(${item.property.property_number}) - ${order.payment_status}`)
                                        .join(', ');

                                    return (
                                        <option key={order.id} value={order.id}>
                                            {`${projectTitle} - ${propertyDetails}`}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="mt-6 flex justify-between">
                            <button
                                onClick={() => setShowModal(false)}
                                className="px-4 py-2 text-gray-600 border rounded-lg"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleWalletPayment}
                                className="px-4 py-2 text-white bg-secondary rounded-lg"
                                disabled={!selectedOrder}
                            >
                                Confirm Payment
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
       </Layout>
    );
};

export default WalletStatement;
