import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const LandingFooter = () => {
    return (
        <footer className="bg-primary text-white py-10">
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-4 md:px-0">
                <div>
                    <p className="">Milescoop ventures ltd</p>
                    <p className="text-gray-400 text-sm mb-4">
                        Your trusted partner in land investment across Kenya. Explore, invest, and build with confidence.
                    </p>
                    <div className="flex space-x-4">
                        <a href="https://facebook.com" className="hover:text-secondary">
                            <FaFacebookF size={20} />
                        </a>
                        <a href="https://twitter.com" className="hover:text-secondary">
                            <FaTwitter size={20} />
                        </a>
                        <a href="https://instagram.com" className="hover:text-secondary">
                            <FaInstagram size={20} />
                        </a>
                        <a href="https://linkedin.com" className="hover:text-secondary">
                            <FaLinkedinIn size={20} />
                        </a>
                    </div>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Navigation</h3>
                    <ul className="space-y-3">
                        <li>
                            <a href="#" className="hover:text-secondary transition">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#projects" className="hover:text-secondary transition">
                                Featured Projects
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="hover:text-secondary transition">
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a href="#faqs" className="hover:text-secondary transition">
                                FAQs
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Support</h3>
                    <ul className="space-y-3">
                        <li>
                            <a href="#help-center" className="hover:text-secondary transition">
                                Help Center
                            </a>
                        </li>
                        <li>
                            <a href="#privacy-policy" className="hover:text-secondary transition">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="#terms-of-service" className="hover:text-secondary transition">
                                Terms of Service
                            </a>
                        </li>
                        <li>
                            <a href="#site-map" className="hover:text-secondary transition">
                                Site Map
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
                    <p className="text-gray-400 mb-2">
                        Phone: <span className="text-secondary">+254 740 173 038 / +254 709 120 800</span>
                    </p>
                    <p className="text-gray-400 mb-2">
                        Email: <span className="text-secondary">info@milescoop.com</span>
                    </p>
                    <p className="text-gray-400">
                        Address: Design Centre, 4th Floor, Tausi Lane, Westlands, Nairobi, Kenya
                    </p>
                </div>
            </div>
            <div className="mt-8 border-t border-gray-700 pt-6 text-center text-sm text-gray-500">
                &copy; 2024 Milescoop. All rights reserved.
            </div>
        </footer>
    );
};

export default LandingFooter;
