import './App.css';
import AppRoutes from './routes';
import React, { useState, useEffect } from 'react';
import Onboarding from './components/Onboarding';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPWA, setIsPWA] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsPWA(true);
      const hasCompletedOnboarding = localStorage.getItem('onboardingComplete');
      if (!hasCompletedOnboarding) {
        setShowOnboarding(true);
      }
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault(); 
      setDeferredPrompt(e);;
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null); // Reset the deferredPrompt state
      });
    }
  };

  const handleOnboardingComplete = () => {
    setShowOnboarding(false); // Hide onboarding screen
    localStorage.setItem('onboardingComplete', 'true'); // Mark onboarding as complete
  };

  return (
    <div className="font-inter">
      {isPWA && showOnboarding ? (
        <Onboarding onComplete={handleOnboardingComplete} />
      ) : (
        <AppRoutes />
      )}

      {deferredPrompt && (
        <button
          className="fixed bottom-5 right-5 bg-blue-500 text-white px-4 py-2 rounded-lg"
          onClick={handleInstallClick}
        >
          Install App
        </button>
      )}
    </div>
  );
}

export default App;
