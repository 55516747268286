import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';  // Import icons for password visibility
import logo from '../../assets/miles_logo.svg'

const SetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);

    var token = new URLSearchParams(url.search).get('token');
    const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            setLoading(true);
            setError('');

            const response = await axios.post('https://api.milescoop.com/api/v1/auth/set-customer-password',
                { password },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'api-key': API_KEY,
                    }
                }
            );

            setLoading(false);
            navigate('/accounts/login');
        } catch (err) {
            setLoading(false);
            setError('Error setting password. Please try again.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
                <img src={logo} className='mx-auto mb-6' alt="Milescoop Logo" />
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Set Your Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6 relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
                        <div className="flex items-center border-b border-gray-300">
                            <input
                                type={showPassword ? 'text' : 'password'} // Toggle password visibility
                                id="password"
                                className="flex-grow bg-transparent outline-none focus:text-secondary px-3.5 py-2 text-gray-700"
                                value={password}
                                placeholder='********'
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}  // Toggle the eye icon
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
                            </button>
                        </div>
                    </div>

                    <div className="mb-6 relative">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <div className="flex items-center border-b border-gray-300">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'} // Toggle password visibility
                                id="confirmPassword"
                                className="flex-grow bg-transparent outline-none focus:text-secondary px-3.5 py-2 text-gray-700"
                                value={confirmPassword}
                                placeholder='********'
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                {showConfirmPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
                            </button>
                        </div>
                    </div>

                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

                    <button
                        type="submit"
                        className="w-full py-2.5 px-4 rounded-md text-white bg-teal-500 hover:bg-teal-600 focus:bg-teal-700 transition-all text-sm font-medium"
                        disabled={loading}
                    >
                        {loading ? 'Setting Password...' : 'Set Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SetPassword;
