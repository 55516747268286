import React from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';

const notificationsData = [
    { id: 1, title: "New Property Listed", description: "A new property in downtown is available for sale", time: "5 mins ago", read: false },
    { id: 2, title: "Offer Accepted", description: "Your offer for 'Greenwood Villa' has been accepted", time: "2 hours ago", read: true },
    { id: 3, title: "Property Tour Reminder", description: "Your scheduled tour of 'Oceanview Apartment' is tomorrow", time: "1 day ago", read: false },
    { id: 4, title: "Contract Signed", description: "Your contract for 'Sunset Apartments' has been successfully signed", time: "2 days ago", read: true },
];

const NotificationItem = ({ title, description, time, read }) => (
    <div className={`p-4 rounded-lg border ${read ? 'bg-white' : 'bg-gray-50'} shadow-sm hover:shadow-md transition duration-300 ease-in-out cursor-pointer mb-4`}>
        <div className="flex justify-between items-center">
            <h4 className={`text-lg font-semibold ${read ? 'text-gray-700' : 'text-gray-900'}`}>{title}</h4>
            <span className="text-sm text-gray-500">{time}</span>
        </div>
        <p className="text-sm text-gray-600 mt-1">{description}</p>
    </div>
);

const Notifications = () => {
    return (
        <Layout text="Notifications">
            <div className="w-full mx-auto">
                <div className="flex items-center justify-between w-full">
                    <h2 className="text-2xl font-semibold hidden md:block mb-6">Notifications</h2>
                    <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
                        <IoMdSearch className="text-gray-600" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="outline-none border-none text-gray-600 font-light text-[14px]"
                        />
                    </div>
                </div>
                <div className="space-y-4">
                    {notificationsData.map(notification => (
                        <NotificationItem
                            key={notification.id}
                            title={notification.title}
                            description={notification.description}
                            time={notification.time}
                            read={notification.read}
                        />
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default Notifications;
