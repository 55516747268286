import React, { useEffect, useState, useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import Layout from '../elements/Layout';
import { useParams } from 'react-router-dom';
import { fetchSingleContract } from '../utils/api/api';
import toast from 'react-hot-toast';
import html2pdf from 'html2pdf.js';
import Loading from '../elements/Loading';
import SalesAgreement from '../minicomponents/SalesAgreement';
import Certificate from '../minicomponents/Certificate';
import OfferLetter from '../minicomponents/OfferLetter';

const ViewContract = () => {
    const [contract, setContract] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const getContract = async () => {
        setLoading(true);
        try {
            const response = await fetchSingleContract(id);
            setContract(response.contract);
        } catch (error) {
            toast.error("Failed to get contract");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getContract();
    }, [id]);

    const pdfRef = useRef();

    const handleDownload = () => {
        const element = pdfRef.current;

        const options = {
            margin: 0.5,
            filename: `_${contract?.customer?.first_name}_${contract?.customer?.last_name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(element).set(options).save();
    };

    return (
        <Layout text={"View Contract"}>
            {loading && <Loading />}
            <div className="flex justify-end w-full items-center mb-4">
                <div className="space-x-2 bg-white px-4 py-1 rounded-md">
                    <button onClick={handleDownload} className="text-black">
                        <FaDownload className="inline-block mr-2" />
                    </button>
                </div>
            </div>

            <div ref={pdfRef} className="">
                {contract?.type === 'offer_letter' && (
                    <OfferLetter contract={contract} />
                )}
                {contract?.type === 'completion_letter' && (
                    <Certificate contract={contract} />
                )}
                {contract?.type === 'sales_agreement' && (
                    <SalesAgreement contract={contract} />
                )}
            </div>
        </Layout>
    );
};

export default ViewContract;           
