import React from 'react';
import { MdPhone, MdEmail, MdLocationOn, MdAccessTime } from 'react-icons/md';

const ContactUs = () => {
    return (
        <div className="bg-white p-8 px-[5%] mx-auto my-12 grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
                <h2 className="text-3xl font-semibold text-primary mb-4">Get in Touch with Us</h2>
                <p className="text-gray-600 mb-6">
                    Whether you have a question about our properties, pricing, or anything else, our team is ready to assist you. Fill out the form below and we will get back to you as soon as possible.
                </p>
                <form className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700" htmlFor="name">Your Name</label>
                        <input
                            type="text"
                            id="name"
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md text-[14px] outline-none focus:border-secondary"
                            placeholder="Enter your name"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md text-[14px] outline-none focus:border-secondary"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700" htmlFor="message">Your Message</label>
                        <textarea
                            id="message"
                            rows="4"
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md text-[14px] outline-none focus:border-secondary"
                            placeholder="Type your message here"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-secondary text-white py-2 rounded-md hover:bg-primary-dark"
                    >
                        Send Message
                    </button>
                </form>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg space-y-6">
                <h3 className="text-2xl font-semibold text-primary">Contact Information</h3>
                <div className="flex items-center space-x-3">
                    <MdPhone size={24} className="text-secondary" />
                    <div>
                        <p className="text-gray-800 font-medium">Phone</p>
                        <p className="text-gray-600">+254 740 173 456</p>
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <MdEmail size={24} className="text-secondary" />
                    <div>
                        <p className="text-gray-800 font-medium">Email</p>
                        <p className="text-gray-600">info@milescoop.com</p>
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <MdLocationOn size={24} className="text-secondary" />
                    <div>
                        <p className="text-gray-800 font-medium">Office Address</p>
                        <p className="text-gray-600">Nairobi, Kenya</p>
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <MdAccessTime size={24} className="text-secondary" />
                    <div>
                        <p className="text-gray-800 font-medium">Working Hours</p>
                        <p className="text-gray-600">Mon - Fri: 8:00 AM - 5:00 PM</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
