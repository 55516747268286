import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from '../../assets/miles_logo.svg';
import image from '../../assets/login.jpg';
import toast from 'react-hot-toast';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { GoogleOAuthProvider } from '@react-oauth/google';

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    id_number: '',
    dob: '',
    gender: '',
    nationality: '',
    city: '',
    state: '',
    country: '',
    password: '',
    referral_code: '',
  });
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [countryCode, setCountryCode] = useState('+254');
  const [countryCodes, setCountryCodes] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data.map(country => ({
          name: country.name.common,
          code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
          flag: country.flags.svg,
        }));

        const sortedCountryData = countryData.sort((a, b) => a.name.localeCompare(b.name));

        setCountryCodes(sortedCountryData);
      } catch (error) {
        console.error('Error fetching country codes:', error);
      }
    };

    fetchCountryCodes();
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get('referralCode');
    if (referralCode) {
      setFormData(prev => ({
        ...prev,
        referral_code: referralCode,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneNumber = (number) => {
    // Extract the last 9 digits
    const lastNineDigits = number.slice(-9);
    // Prepend the selected country code
    return countryCode + lastNineDigits;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setError('Email and password are required');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const processedPhoneNumber = handlePhoneNumber(formData.phone_number);

      const response = await axios.post('https://api.milescoop.com/api/v1/auth/customer-signup',
        { ...formData, phone_number: processedPhoneNumber },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': API_KEY,
          }
        }
      );

      const { token, customer } = response.data;
      const userId = customer.id;

      Cookies.set('access_token', token, { expires: 1 });
      Cookies.set('user_id', userId, { expires: 1 });
      toast.success("Registration successful");
      navigate('/dashboard');
    } catch (err) {
      const errorMessage = error.response?.data?.message || error.message || error.response?.data?.errors?.join(', ') || 'Error signing up. Please try again.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (googleResponse) => {
    try {
      const googleToken = googleResponse.credential;

      const response = await axios.post(
        'https://api.milescoop.com/api/v1/auth/social-signin',
        {
          account: 'google',
          token: googleToken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': API_KEY,
          }
        }
      );

      const { token, customer } = response.data;
      const userId = customer.id;

      Cookies.set('access_token', token, { expires: 1 });
      Cookies.set('user_id', userId, { expires: 1 });

      toast.success("Google login successful");
      navigate('/dashboard');
    } catch (error) {
      setError('Google login failed. Please try again.');
    }
  };

  const handleFacebookLogin = () => {
    console.log('Facebook Login');
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCountrySelect = (country) => {
    setCountryCode(country.code);
    setFormData({
      ...formData,
      country: country.name,
    });
    setIsDropdownOpen(false);
  };

  return (
    <GoogleOAuthProvider clientId="71841088397-n30t73grcio6uti847fs86avg0hdh0ak.apps.googleusercontent.com">
      <div className="flex justify-center items-center min-h-screen w-full">
        <div className="flex flex-col h-screen w-full md:w-[50%] overflow-y-auto">
          <div className="bg-white p-8 rounded mx-auto w-full md:w-[60%]">
            <img src={logo} className='mx-auto mb-4' alt="MileScoop Logo" />
            <p className="text-2xl font-bold text-center">Welcome to Milescoop</p>
            <p className='text-center mb-4 text-gray-600 text-[14px]'>Access exclusive land listings property recommendations.</p>
            <form onSubmit={handleSubmit}>
              <>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.email}
                    placeholder='example@example.com'
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="flex items-center gap-4 w-full">
                  <div className="mb-4 w-full">
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.first_name}
                      placeholder='John'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-4 w-full">
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.last_name}
                      placeholder='Doe'
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <div className="flex gap-2 items-center">
                    <div className="relative w-[35%]">
                      <button
                        type="button"
                        className="border-b w-full border-gray-200 bg-transparent text-left px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                        onClick={toggleDropdown}
                      >
                        {countryCode}
                        <img src={countryCodes.find(country => country.code === countryCode)?.flag} alt="flag" className="inline-block w-5 h-5 ml-2" />
                      </button>
                      {isDropdownOpen && (
                        <div className="absolute z-10 bg-white border border-gray-200 rounded mt-1 shadow-md max-h-[80vh] overflow-y-auto w-[300px]">
                          {countryCodes.map(country => (
                            <div
                              key={country.code}
                              className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCountrySelect(country)}
                            >
                              <img src={country.flag} alt={`${country.name} flag`} className="w-5 h-5 mr-2" />
                              <span className='flex items-center gap-2'>{country.code} {country.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <input
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.phone_number}
                      placeholder='712345678'
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="flex items-center w-full gap-4">
                  <div className="mb-4 w-full">
                    <label htmlFor="id_number" className="block text-sm font-medium text-gray-700">ID Number</label>
                    <input
                      type="text"
                      id="id_number"
                      name="id_number"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.id_number}
                      placeholder='12345678'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label htmlFor="dob" className="block text-sm font-medium text-gray-700">Date of Birth</label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
                  <select
                    id="gender"
                    name="gender"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex items-center w-full gap-4">
                  <div className="w-full mb-4">
                    <label htmlFor="nationality" className="block text-sm font-medium text-gray-700">Nationality</label>
                    <input
                      type="text"
                      id="nationality"
                      name="nationality"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.nationality}
                      placeholder='Kenyan'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      value={formData.city}
                      placeholder='Nairobi'
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700">State</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.state}
                    placeholder='Nairobi'
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.country}
                    placeholder='Kenya'
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password (8 or more characters)</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.password}
                    placeholder='********'
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    required
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    className="mr-2"
                  />
                  <label htmlFor="agreeToTerms" className="text-[12px] text-gray-700">
                    I agree to the
                    <Link to="/terms" className="text-secondary underline mx-1">Terms & Conditions</Link>
                    and
                    <Link to="/terms" className="text-secondary underline mx-1">Privacy Policy</Link>.
                  </label>
                </div>
                <div className="flex justify-end gap-2 items-center">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary"
                  >
                    {loading ? 'Signing up...' : 'Sign up'}
                  </button>
                </div>
              </>
            </form>
            <p className="text-[14px] mt-2 text-center ">Already have an account? <Link className='text-secondary underline font-medium' to='/accounts/login'>Login</Link></p>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <div className="my-6 text-center">
              <div className="flex items-center gap-2">
                <div className="bg-gray-300 w-full h-[1px]" />
                <p className="text-gray-500 text-sm">or</p>
                <div className="bg-gray-300 w-full h-[1px]" />
              </div>
              <div className="flex flex-col justify-center gap-4 mt-3">
                <button
                  onClick={handleGoogleLogin}
                  className="flex items-center justify-center gap-2 p-2 text-[14px] rounded-full border bg-gray-100 hover:bg-gray-200"
                >
                  <FaGoogle className="text-red-500 text-xl" />
                  Continue with Google
                </button>
                <button
                  onClick={handleFacebookLogin}
                  className="flex items-center justify-center gap-2 p-2 text-[14px] rounded-full border bg-gray-100 hover:bg-gray-200"
                >
                  <FaFacebook className="text-blue-600 text-xl" />
                  Continue with Facebook
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-screen hidden md:flex">
          <img src={image} className='object-cover' alt="Side banner" />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignUp;