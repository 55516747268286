import React from 'react';
import Slider from 'react-slick';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const testimonials = [
    {
        name: 'John Mwangi',
        image: 'https://randomuser.me/api/portraits/men/32.jpg',
        description: 'Milescoop made the process of purchasing land seamless and worry-free. I secured my plot in less than a week, and the customer support was outstanding.',
    },
    {
        name: 'Grace Wanjiru',
        image: 'https://randomuser.me/api/portraits/women/44.jpg',
        description: 'I was hesitant at first, but Milescoop offered me transparency and a great deal on prime land in Kitengela. I now own my first piece of land, thanks to their great team.',
    },
    {
        name: 'Peter Kamau',
        image: 'https://randomuser.me/api/portraits/men/41.jpg',
        description: 'The best decision I made was investing in land with Milescoop. They guided me through the entire process with integrity and professionalism.',
    },
    {
        name: 'Lucy Njoroge',
        image: 'https://randomuser.me/api/portraits/women/51.jpg',
        description: 'I am extremely satisfied with my purchase. The land I bought in Nanyuki is a great investment. I highly recommend Milescoop for their transparency and great customer service.',
    },
    {
        name: 'Joseph Otieno',
        image: 'https://randomuser.me/api/portraits/men/52.jpg',
        description: 'If you’re looking for genuine land deals, Milescoop is the way to go. They were patient and explained everything, which made me trust the process entirely.',
    },
];

const TestimonialSlider = () => {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <div className="testimonial-slider pb-8">
            <div className="w-[150px] bg-primary h-[2px] mx-auto mt-6 " />
            <h2 className="text-2xl font-bold text-center mt-2">What Our Customers Say</h2>
            <p className="text-gray-600 text-[14px] text-center">Hear from our satisfied clients and customers</p>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="p-4">
                        <div className="bg-white rounded-lg shadow-md border border-gray-100 p-6 flex flex-col items-center">                           
                            <p className="text-gray-600 text-sm text-center">
                                "{testimonial.description}"
                            </p>
                            <div className="mt-4 w-full flex items-center justify-between">                                
                                <h3 className="font-semibold text-lg mb-2">{testimonial.name}</h3>
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-14 h-14 rounded-full mb-4"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default TestimonialSlider;
