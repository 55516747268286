import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../utils/api/axiosInstance';
import axios from 'axios';

const VerifyCode = () => {
    const [resetCode, setResetCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        setError('');

        try {
            const response = await axios.post(
                'https://api.milescoop.com/api/v1/auth/confirm-password-reset-code',
                { code: resetCode },
                {
                    headers: {
                        'api-key': `14CA4D6A5CE4D441CFBFB23CCB699`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            const token = response.data?.token;

            if (token) {
                setMessage(response.data.message || 'Code verified successfully!');
                setTimeout(() => {
                    navigate('/accounts/reset-password', { state: { email, token } });
                }, 2000);
            } else {
                setError('Token not found in the response.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Invalid reset code. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4">
                    Verify Code
                </h2>
                <p className="text-sm text-gray-600 text-center mb-6">
                    Enter the reset code sent to your email.
                </p>

                {message && <div className="text-green-600 text-sm mb-4">{message}</div>}
                {error && <div className="text-red-600 text-sm mb-4">{error}</div>}

                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="resetCode" className="block text-sm text-gray-600">
                            Reset Code
                        </label>
                        <input
                            type="text"
                            id="resetCode"
                            value={resetCode}
                            onChange={(e) => setResetCode(e.target.value)}
                            placeholder="Enter the reset code"
                            className="w-full px-4 py-2 mt-1 text-sm border rounded-lg focus:ring-indigo-500 focus:border-indigo-500 outline-none"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full py-2 rounded-lg text-sm font-medium transition ${isLoading
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-indigo-600 text-white hover:bg-indigo-500'
                            }`}
                    >
                        {isLoading ? 'Verifying...' : 'Verify Code'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default VerifyCode;
