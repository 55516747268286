import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCurrentUser = async (userId, token) => {
        try {
            const response = await axios.post(
                'https://api.milescoop.com/api/v1/customers/get',
                { id: userId },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                    },
                }
            );

            setUser(response.data.customer);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                Cookies.remove('access_token');
                Cookies.remove('user_id');
                window.location.assign('/accounts/login');
            } else {
                console.error(err);
                setError('Failed to fetch user data.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = Cookies.get('access_token');
        const userId = Cookies.get('user_id');

        if (token && userId) {
            fetchCurrentUser(userId, token);
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ user, loading, error }}>
            {children}
        </AuthContext.Provider>
    );
};