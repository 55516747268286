import React, { useEffect, useState } from 'react';
import { fetchProjects, createCustomBooking } from '../utils/api/api'; // API call functions
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

const CustomBookingForm = () => {
    const [projects, setProjects] = useState([]);
    const [formData, setFormData] = useState({
        project_id: '',
        description: '',
        date: '',
        time: '',
    });

    const customerId = Cookies.get('user_id');
    const times = ['8:30', '9:30', '10:30', '11:30', '12:30', '1:30', '2:30', '3:30', '4:30'];

    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await fetchProjects();
                setProjects(response.projects); // Assuming response has a 'projects' array
            } catch (error) {
                toast.error('Failed to fetch projects');
            }
        };

        getProjects();
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle time selection
    const handleTimeSelect = (selectedTime) => {
        setFormData((prevData) => ({
            ...prevData,
            time: selectedTime,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const bookingData = {
            customer_id: customerId,
            project_id: formData.project_id,
            description: formData.description,
            date: formData.date,
            time: formData.time,
        };

        try {
            await createCustomBooking(bookingData);
            toast.success('Custom booking created');
            window.location.reload();
        } catch (error) {
            toast.error('Failed to create booking');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
                <label htmlFor="project_id" className="block text-black font-medium mb-1">
                    Project <span className="text-secondary">*</span>
                </label>
                <select
                    id="project_id"
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleInputChange}
                    className="border w-full bg-transparent border-gray-200 text-gray-600 p-2 rounded-md outline-none focus:border-secondary"
                >
                    <option value="">Select Project</option>
                    {projects.map((project) => (
                        <option key={project.id} value={project.id}>
                            {project.title} - {project.location}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label htmlFor="description" className="block text-black font-medium mb-1">
                    Description <span className="text-secondary">*</span>
                </label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="border w-full border-gray-200 text-gray-600 p-2 rounded-md outline-none focus:border-secondary"
                    rows="4"
                    placeholder="Enter description"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="date" className="block text-black font-medium mb-1">
                    Date <span className="text-secondary">*</span>
                </label>
                <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    className="border w-full border-gray-200 text-gray-600 p-2 rounded-md outline-none focus:border-secondary"
                />
            </div>

            <div className="mb-4">
                <label className="block text-black font-medium mb-1">
                    Time <span className="text-secondary">*</span>
                </label>
                <div className="flex flex-wrap gap-2">
                    {times.map((time) => (
                        <button
                            key={time}
                            type="button"
                            onClick={() => handleTimeSelect(time)}
                            className={`px-4 py-2 rounded-md ${formData.time === time
                                    ? 'bg-secondary text-white'
                                    : 'bg-gray-200 text-black hover:bg-gray-300'
                                }`}
                        >
                            {time}
                        </button>
                    ))}
                </div>
            </div>

            <div className="w-full flex items-center justify-end">
                <button
                    type="submit"
                    className="bg-secondary text-white px-6 py-1.5 text-[14px] font-medium rounded-md"
                >
                    Submit Booking
                </button>
            </div>
        </form>
    );
};

export default CustomBookingForm;
