import React, { useState, useRef } from 'react';
import axios from 'axios';
import image from '../../assets/login.jpg';
import logo from '../../assets/miles_logo.svg';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const OTP = () => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const inputRefs = useRef([]);
    const navigate = useNavigate();

    const handleChange = (element, index) => {
        const value = element.value.replace(/\D/g, '');
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const submitOtp = async () => {
        const otpValue = otp.join('');
        if (otpValue.length < 6) {
            setError('Please enter a complete OTP.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('https://api.milescoop.com/api/v1/auth/verify-otp', {
                otp: Number(otpValue)
            }, {
                headers: {
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                }
            });

            const { token, user } = response.data;
            const userId = user.id;

            Cookies.set('access_token', token, { expires: 1 });
            Cookies.set('user_id', userId, { expires: 1 });
            localStorage.setItem('user', JSON.stringify(user));

            navigate('/dashboard');
            window.location.reload();

        } catch (error) {
            setError(error.response?.data?.message || 'Failed to verify OTP.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen w-full bg-white">
            <div className="flex h-screen w-full my-auto items-center justify-center overflow-y-auto">
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center px-8 py-6">
                    <img src={logo} className='' alt="Logo" />
                    <h2 className="text-2xl font-semibold text-gray-800 my-4">Enter OTP</h2>
                    <p className="text-sm text-gray-500 text-center mb-2">
                        Please enter the 6-digit code sent to your email & phone.
                    </p>
                    {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                    <div className="flex space-x-2 mb-4">
                        {otp.map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={otp[index]}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className="w-10 h-10 border border-gray-300 text-center text-xl font-medium rounded-lg focus:outline-none focus:border-secondary transition duration-200 transform focus:scale-105"
                            />
                        ))}
                    </div>
                    <button
                        className={`px-6 py-1.5 text-[13px] font-medium mt-4 text-white bg-secondary rounded-lg hover:bg-primary transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={submitOtp}
                        disabled={loading}
                    >
                        {loading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </div>
                <div className="w-1/2 h-screen hidden md:flex">
                    <img src={image} className='object-cover' alt="Side banner" />
                </div>
            </div>
        </div>
    );
};

export default OTP;
