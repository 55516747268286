import React, { useState } from 'react';

const FAQSection = () => {
    const [openFAQ, setOpenFAQ] = useState(0);

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    const faqs = [
        {
            question: "What documents do I need to buy land with Milescoop?",
            answer: "You'll need a valid national ID, your KRA PIN, and any necessary financial documents depending on your payment plan. Our legal team will guide you through the process to ensure you have everything covered."
        },
        {
            question: "Does Milescoop offer payment plans?",
            answer: "Yes, we offer flexible payment plans. You can pay in installments over a period of time that best suits you, with a minimum deposit required upfront."
        },
        {
            question: "How do I know the land is genuine?",
            answer: "We conduct thorough due diligence on all properties before listing them. You can also perform an independent search at the land registry for added peace of mind."
        },
        {
            question: "Can I transfer ownership to someone else?",
            answer: "Yes, you can transfer ownership once you've completed the payment and received the title deed. Our team will help facilitate the transfer process."
        }
    ];

    return (
        <div className="flex flex-col md:flex-row gap-4 my-6 px-[5%]">
            <div className="flex flex-col gap-6 w-full md:w-[50%]">
                <div className="flex items-center gap-3">
                    <div className="w-[150px] bg-primary h-[2px]" />
                    <p className="text-primary font-medium text-[13px]">Frequently Asked Questions</p>
                </div>
                <p className="text-primary font-bold text-[38px] -mt-4 ">Common questions asked about Milescoop Ventures & our services</p>
                <p className="text-gray-600 text-[14px]">Here are some important questions that are frequently asked and the answers to the questions.</p>
                <p className="">Have a different question?</p>
                <button className="bg-secondary w-fit px-6 py-1.5 rounded-full text-white text-[14px] font-medium -mt-4">Contact Us</button>
            </div>
            <div className="w-full md:w-[50%] mx-auto my-8">
                <h2 className="text-2xl font-semibold text-primary mb-4">Frequently Asked Questions</h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="mb-4 border-b border-gray-200">
                        <div
                            className="flex justify-between items-center py-2 cursor-pointer"
                            onClick={() => toggleFAQ(index)}
                        >
                            <h3 className="text-lg font-medium text-gray-800">{faq.question}</h3>
                            <span className={`text-primary transform ${openFAQ === index ? 'rotate-180' : ''}`}>
                                {openFAQ === index ? '-' : '+'}
                            </span>
                        </div>
                        {openFAQ === index && (
                            <div className="pb-2 text-gray-600">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
