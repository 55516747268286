import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPaymentStatus, fetchOrder, processCardPayment, processMpesaPayment } from '../utils/api/api';
import moment from 'moment';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Modal from '../elements/Modal';
import { Oval } from 'react-loader-spinner';
import Loading from '../elements/Loading';

const PaymentPage = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('mpesa');
    const [amount, setAmount] = useState('');
    const [phone, setPhone] = useState('');
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
    });

    const { id } = useParams();
    const cu_id = Cookies.get('user_id');
    const mpesa_logo = 'https://imgs.search.brave.com/ksKqVibKAMDeEPrAJiYsygtSjr-is_IBO44A08W82Ts/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8w/LzBiL00tUEVTQS5w/bmc';
    const visa_logo = 'https://imgs.search.brave.com/bNsCAqw-xAbHOBLJ8nQRkVsPfIQJGxTG2tnzaQY6ytk/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zZWVr/bG9nby5jb20vaW1h/Z2VzL1YvVklTQS1s/b2dvLTE2RjRGODJE/MTMtc2Vla2xvZ28u/Y29tLnBuZw';
    const options = [
        { value: 'mpesa', label: 'Mpesa', logo: mpesa_logo },
        { value: 'card', label: 'Visa/Mastercard', logo: visa_logo }
    ];

    const getOrder = async () => {
        setLoading(true);
        try {
            const response = await fetchOrder(id);
            const fetchedOrder = response.order;
            setOrder(fetchedOrder);

            if (fetchedOrder?.payment_type === 'one_time') {
                setAmount(Number(fetchedOrder.amount));
            } else if (fetchedOrder?.payment_type === 'installments') {
                const firstPendingInstallment = fetchedOrder.installments.find(inst => inst.payment_status === 'pending');
                if (firstPendingInstallment) {
                    setAmount(Number(firstPendingInstallment.amount));
                }
            }

            setLoading(false);
        } catch (error) {
            toast.error('Failed to get order');
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrder();
    }, [id]);

    const parseExpiryDate = (expiryDate) => {
        const [month, year] = expiryDate.split('/');
        return { month, year };
    };

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const customerId = cu_id;
        const orderId = id;

        if (paymentMethod === 'mpesa') {
            if (!phone) {
                toast.error('Please enter your phone number for Mpesa');
                return;
            }

            const mpesaPayload = {
                order_id: orderId,
                customer_id: customerId,
                amount: amount,
                phone_number: phone,
            };
            setIsloading(true);
            setLoading(false);
            try {
                const response = await processMpesaPayment(mpesaPayload);
                const { message, payment_id } = response;

                toast.success(message);
                pollPaymentStatus(payment_id);
            } catch (error) {
                toast.error('Mpesa payment failed');
                setIsloading(false);
            } finally {

            }

        } else if (paymentMethod === 'card') {
            if (!cardDetails.cardNumber || !cardDetails.expiryDate || !cardDetails.cvv) {
                toast.error('Please fill in all card details');
                return;
            }

            const { month, year } = parseExpiryDate(cardDetails.expiryDate);
            const cardPayload = {
                order_id: orderId,
                customer_id: customerId,
                amount: amount,
                card_number: cardDetails.cardNumber,
                card_expiry_month: month,
                card_expiry_year: `20${year}`,
                cvc: cardDetails.cvv,
            };

            try {
                await processCardPayment(cardPayload);
                toast.success('Card Payment successfully processed');
            } catch (error) {
                toast.error('Card payment failed');
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const pollPaymentStatus = (paymentId) => {
        const interval = setInterval(async () => {
            try {
                const response = await checkPaymentStatus(paymentId.toString());

                const { status } = response.payment;
                console.log(status)

                if (status === 'completed') {
                    clearInterval(interval);
                    toast.success('Payment successfully completed');
                    setIsloading(false);
                    navigate('/dashboard/payments')
                } else if (status === 'failed') {
                    clearInterval(interval);
                    toast.error('Payment failed');
                    setIsloading(false);
                }
            } catch (error) {
                clearInterval(interval);
                setIsloading(false);

                if (error.response && error.response.status === 400) {
                    toast.error('Payment failed due to client error (400)');
                } else if (error.response && error.response.status === 500) {
                    toast.error('Server error. Please try again later.');
                } else {
                    toast.error('Error checking payment status');
                }
            }
        }, 4000);
    };

    return (
        <Layout text="Payment" backgroundColor='bg-white'>
            <h2 className="text-[24px] font-semibold text-black mb-2">Confirm & pay</h2>
            {loading ? <Loading /> : (
                <div className="flex w-full flex-col gap-4 md:flex-row">
                    <div className="flex flex-col gap-4 w-full md:w-[60%] border border-gray-200 p-4 rounded-md">
                        <form onSubmit={handlePaymentSubmit} className="bg-white p-4 rounded-md">
                            <div className="flex mb-2 relative items-center w-full justify-between">
                                <h3 className="text-lg font-medium mb-2">Pay with</h3>
                                <div className="flex items-center gap-2">
                                    <img src={mpesa_logo} className='h-8 w-auto' alt="" />
                                    <img src={visa_logo} className='h-6 w-auto' alt="" />
                                </div>
                            </div>
                            <div className="w-full mb-4 p-2 border rounded-md bg-white relative cursor-pointer">
                                <div className="flex items-center gap-2">
                                    <img src={options.find(option => option.value === paymentMethod)?.logo} alt={paymentMethod} className="h-6 w-auto" />
                                    <span>{options.find(option => option.value === paymentMethod)?.label}</span>
                                </div>
                                <select
                                    className="absolute inset-0 opacity-0 cursor-pointer border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                    value={paymentMethod}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {paymentMethod === 'mpesa' ? (
                                <div className="flex flex-col md:flex-row items-center gap-4 w-full">
                                    <div className="md:mb-4 w-full">
                                        <label className="block text-sm font-medium text-gray-700">Amount (KSh)</label>
                                        <input
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            className="border rounded-md w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                            placeholder="Enter amount"
                                        />
                                    </div>
                                    <div className="mb-4 w-full">
                                        <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                                        <input
                                            type="text"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            className="border rounded-md w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="mb-6">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Amount (KSh)</label>
                                        <input
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            className="border w-full border-gray-300 bg-white rounded-md px-4 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-[14px] text-gray-600"
                                            placeholder="Enter amount"
                                        />
                                    </div>

                                    <div className="mb-6 relative">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Card Number</label>
                                        <input
                                            type="text"
                                            value={cardDetails.cardNumber}
                                            onChange={(e) => setCardDetails({ ...cardDetails, cardNumber: e.target.value })}
                                            className="border w-full border-gray-300 bg-white rounded-md px-4 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-[14px] text-gray-600"
                                            placeholder="xxxx xxxx xxxx xxxx"
                                            maxLength="16"
                                        />
                                        <span className="absolute right-4 top-9 text-gray-400">💳</span>
                                    </div>

                                    <div className="mb-6 grid grid-cols-2 gap-4">
                                        <div className="relative">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Expiry Date</label>
                                            <input
                                                type="text"
                                                value={cardDetails.expiryDate}
                                                onChange={(e) => setCardDetails({ ...cardDetails, expiryDate: e.target.value })}
                                                className="border w-full border-gray-300 bg-white rounded-md px-4 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-[14px] text-gray-600"
                                                placeholder="MM/YY"
                                                maxLength="5"
                                            />
                                            <span className="absolute right-4 top-9 text-gray-400">📅</span>
                                        </div>

                                        <div className="relative">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">CVV</label>
                                            <input
                                                type="text"
                                                value={cardDetails.cvv}
                                                onChange={(e) => setCardDetails({ ...cardDetails, cvv: e.target.value })}
                                                className="border w-full border-gray-300 bg-white rounded-md px-4 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-[14px] text-gray-600"
                                                placeholder="XXX"
                                                maxLength="3"
                                            />
                                            <span className="absolute right-4 top-9 text-gray-400">🔒</span>
                                        </div>
                                    </div>
                                </>
                            )}

                            <button type="submit" className="bg-secondary text-[14px] text-white w-full py-1.5 font-medium rounded-md">
                                Proceed with Payment
                            </button>
                        </form>

                        {order?.installments && order?.installments?.length > 0 && (
                            <div className="mt-4 rounded-md">
                                {order?.installments
                                    ?.sort((a, b) => a.id - b.id)
                                    .map((installment, index) => (
                                        <div
                                            key={installment.id}
                                            className={`p-2 flex items-center gap-2 justify-between w-full rounded-md ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                                        >
                                            <div className="flex items-center gap-2">
                                                <p className={`flex items-center justify-center rounded-full w-[40px] h-[40px] text-white ${installment?.invoice?.payment_status === 'paid'
                                                    ? 'bg-green-600'
                                                    : installment?.invoice?.payment_status === 'pending'
                                                        ? 'bg-primary'
                                                        : installment?.invoice?.payment_status === 'partially_paid'
                                                            ? 'bg-secondary'
                                                            : 'bg-red-600'
                                                    } `}>{index + 1}</p>
                                                <div className="flex flex-col">
                                                    <p className="capitalize text-[14px] font-medium">{installment.type}</p>
                                                    <p className="font-medium text-black text-[13px] ">Kes. {Number(installment.amount).toLocaleString("KES")}</p>
                                                </div>
                                            </div>
                                            <div className="flex text-end flex-col">
                                                <p className="text-[13px] text-gray-600">{moment(installment.due_date).format('DD MMM, YYYY')}</p>
                                                <p
                                                    className={`capitalize text-[13px] font-light ${installment.payment_status === 'complete'
                                                        ? 'text-green-600'
                                                        : installment.payment_status === 'pending'
                                                            ? 'text-secondary'
                                                            : 'text-red-600'
                                                        }`}
                                                >
                                                    {installment.payment_status}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className="w-full md:w-[40%] bg-white border border-gray-200 h-fit p-4 rounded-md hidden md:flex flex-col">
                        <div className="flex items-center gap-2">
                            <img className='h-[90px] w-auto' src="https://imgs.search.brave.com/1qOy-0Ymw2K6EdSAI4515c9T4mh-eoIQbDsp-koZkLw/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA1Lzk3LzQ3Lzk1/LzM2MF9GXzU5NzQ3/OTU1Nl83YmJRN3Q0/WjhrM3hiQWxvSEZI/VmRaSWl6V0sxUGRP/by5qcGc" alt="" />
                            <div className="flex flex-col">
                                <p className="">{order?.property?.title}</p>
                                <p className="">{order?.property?.property_number}</p>
                            </div>
                        </div>
                        <p className="flex w-full items-center justify-between mt-2 text-[14px] text-gray-600">Plot number <span className="text-primary font-medium">{order?.property?.property_number}</span></p>
                        <p className="flex w-full items-center justify-between mt-2 text-[14px] text-gray-600">Size <span className="text-primary font-medium">{order?.property?.size}</span></p>
                        <p className="flex w-full items-center justify-between mt-2 text-[14px] text-gray-600">Status <span className="text-primary font-medium">{order?.property?.status}</span></p>
                        <p className="flex w-full items-center justify-between mt-2 text-[14px] text-gray-600">Price <span className="text-primary font-medium">Kes. {Number(order?.amount).toLocaleString("KES")}</span></p>
                        <div className="h-[2px] bg-gray-200 w-full my-4" />
                        <div className="flex w-full mx-auto mb-3">
                            <img src={mpesa_logo} className='w-auto h-[80px] mx-auto' alt="" />
                        </div>
                        <div className="flex w-full justify-between border-b border-gray-200 pb-1">
                            <p className="text-[14px] text-gray-600">Paybill number</p>
                            <p className="text-primary font-medium text-[14px]"></p>
                        </div>
                        <div className="flex w-full justify-between border-b border-gray-200 mt-4 pb-1">
                            <p className="text-[14px] text-gray-600">Account number</p>
                            <p className="text-primary font-medium text-[14px]"></p>
                        </div>
                    </div>
                </div>
            )}
            {isLoading && (
                <Modal
                    onClose={() => setIsloading(false)}
                    title="Payment"

                >
                    <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                        Payment Initiated Successfully!
                    </h2>
                    <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                        Check your phone to enter your M-Pesa PIN and complete the transaction.
                    </p>
                    <div className="w-full flex justify-center items-center">
                        <Oval
                            height={50}
                            width={50}
                            color="#007BFF"
                            visible={true}
                            secondaryColor="#4f4f4f"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default PaymentPage;
