import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AiOutlineHome, AiOutlineFileText, AiOutlineCreditCard, AiOutlineUser } from 'react-icons/ai';

const BottomNav = () => {
    const location = useLocation();

    const navItems = [
        { name: 'Home', path: '/dashboard', icon: AiOutlineHome },
        { name: 'Invoices', path: '/dashboard/invoices', icon: AiOutlineFileText },
        { name: 'Payments', path: '/dashboard/payments', icon: AiOutlineCreditCard },
        { name: 'Account', path: '/dashboard/settings', icon: AiOutlineUser },
    ];

    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixed bottom-0 left-0 w-full bg-white text-primary shadow-lg z-50 md:hidden">
            <div className="flex justify-between items-center py-3 px-6">
                {navItems.map((item) => {
                    const Icon = item.icon;
                    const active = isActive(item.path);
                    return (
                        <Link
                            to={item.path}
                            key={item.name}
                            className={`flex flex-col items-center transition-all duration-300 ${active ? 'text-primary' : 'text-gray-600'}`}
                        >
                            <div
                                className={`p-2 rounded-lg shadow-md transition-transform duration-300 ${active ? 'bg-white text-primary scale-110' : ''}`}
                            >
                                <Icon className={`w-6 h-6 ${active ? 'text-indigo-500' : 'text-gray-600'}`} />
                            </div>
                            <span className={`mt-1 text-sm font-semibold ${active ? 'text-primary' : 'text-gray-600'}`}>
                                {item.name}
                            </span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default BottomNav;
