import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { fetchProjects, submitReferral } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import pic from '../assets/refer.jpeg'
import { MdClose } from 'react-icons/md';

const Referral = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [isReferralUsed, setIsReferralUsed] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        username: '',
        phone_number: '',
        email: '',
        project_id: '',
        assigned_to: null,
        status: 'new',
        priority: 'hot',
        last_activity_date: null,
        next_follow_up: null,
        source: 'referral',
    });

    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await fetchProjects();
                setProjects(response.projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        getProjects();
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setReferralCode(user.referral_code);
            setIsReferralUsed(user.is_referral_used);
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode);
        toast.success("Referral code copied!");
    };

    const handleCopyReferralLink = () => {
        navigator.clipboard.writeText(referralLink);
        toast.success("Referral link copied!");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await submitReferral(formData);
            toast.success("Referall sent");
            window.location.reload();
        } catch (error) {
            toast.error('request failed');
        } finally {
            setLoading(false);
        }
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            setOpen(false);
        }, 300);
    };

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };
    const referralLink = `https://client.milescoop.com/accounts/sign-up?referralCode=${referralCode}`;

    return (
        <Layout text="Referral">
            {loading && <Loading />}
            <div className="flex flex-col items-center justify-center">
                <div className="max-w-2xl text-center mb-8">
                    <h1 className="text-3xl md:text-5xl font-bold text-red-500">Refer & get 3 - 5% off with Milescoop</h1>
                    <p className="text-gray-600 mt-4 text-[16px] font-light">
                        Invite your friends and family to join Milescoop, and earn a 3 - 5% discount off your purchase for every successful referral. Help them find their dream property, while you benefit!
                    </p>
                </div>

                <div className="w-full max-w-4xl grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                    <div className="flex flex-col items-center text-center p-6 bg-white shadow-sm rounded-md">
                        <div className="bg-secondary text-white w-[55px] h-[55px] text-center items-center flex justify-center rounded-full mb-4">
                            <span className="text-[22px] font-medium">1</span>
                        </div>
                        <h2 className="text-xl font-semibold text-black">Sign Up or Log In</h2>
                        <p className="text-gray-600 text-[14px] mt-2">
                            Create a free account or log in to your existing account to start referring friends.
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center p-6 bg-white shadow-sm rounded-md">
                        <div className="bg-secondary text-white w-[55px] h-[55px] text-center items-center flex justify-center rounded-full mb-4">
                            <span className="text-[22px] font-medium">2</span>
                        </div>
                        <h2 className="text-xl font-semibold text-black">Fill the Referral Form</h2>
                        <p className="text-gray-600 text-[14px] mt-2">
                            Provide the details of the person you're referring and submit the form. We'll handle the rest!
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center p-6 bg-white shadow-sm rounded-md">
                        <div className="bg-secondary text-white w-[55px] h-[55px] text-center items-center flex justify-center rounded-full mb-4">
                            <span className="text-[22px] font-medium">3</span>
                        </div>
                        <h2 className="text-xl font-semibold text-black">Earn Rewards</h2>
                        <p className="text-gray-600 text-[14px] mt-2">
                            For every successful property purchase made through your referral, you'll receive a reward. It's that simple!
                        </p>
                    </div>
                </div>

                <div className="w-full max-w-lg bg-white shadow-sm rounded-md p-6">
                    <p className="text-center font-semibold text-[22px] mb-4">Start referring today</p>
                    <div className="w-full flex items-center gap-3">
                        <div className="w-full border border-gray-200 rounded-md p-2">
                            <p className="text-gray-600 text-[14px]">{referralCode}</p>
                        </div>
                        <button
                            onClick={handleCopyReferralCode}
                            className="bg-secondary px-6 py-2 text-[14px] text-white rounded-md"
                        >
                            Copy
                        </button>
                    </div>
                    <div className="w-full mt-3 flex flex-col items-center gap-3">
                        <div className="w-full border border-gray-200 rounded-md p-2">
                            <p className="text-gray-600 text-[14px] truncate">{referralLink}</p>
                        </div>
                        <button
                            onClick={handleCopyReferralLink}
                            className="w-full bg-secondary px-6 py-1.5 text-[13px] text-white rounded-md"
                        >
                            Copy Link
                        </button>
                    </div>
                    <p className="text-center mt-2 text-gray-600 text-[14px]">
                        Instead of copying the link, you can simply click
                        <span
                            onClick={() => setOpen(true)}
                            className="text-secondary font-medium px-2 underline text-[16px] cursor-pointer"
                        >
                            here
                        </span>
                        on it to fill out the referral form. Thanks for taking the time to complete it!
                    </p>
                    {isReferralUsed && (
                        <p className="text-center mt-4 text-gray-500 text-[14px]">
                            Your referral code has been used successfully!
                        </p>
                    )}
                </div>
                <div className="mt-6 text-center">
                    <p className="text-gray-500 text-[14px]">*Terms and conditions apply. Contact Milescoop for more details.</p>
                </div>
            </div>

            {open && (
                <div
                    className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                    onClick={handleOutsideClick}
                >
                    <div className={`bg-[#F5CE33] rounded-lg shadow-lg w-full md:w-[90%] h-[90vh] overflow-y-auto transform transition-transform duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
                        <div className="flex justify-between items-center p-4 border-b border-gray-100">
                            <h3 className="text-lg font-semibold text-black"></h3>
                            <button
                                className="text-white"
                                onClick={handleClose}
                            >
                                <MdClose size={24} />
                            </button>
                        </div>
                        <div className="p-4 flex flex-col md:flex-row w-full">
                            <div className="h-full w-full md:w-[50%] md:p-6 flex flex-col">
                                <p className="text-[40px] font-bold -mt-6">Land purchase referallls. Get 3 - 5% off your next purchase</p>
                                <img src={pic} className='' alt="" />
                            </div>
                            <form className='w-full md:w-[50%] bg-white rounded-md p-6 h-full' onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="title" className="block text-black font-medium text-[14px] mb-2">
                                        Referral Title
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        placeholder="Enter referral title"
                                        value={formData.title}
                                        onChange={handleChange}
                                        className="w-full border border-gray-200 rounded-md p-2 text-gray-600 text-[14px] outline-none focus:border-secondary"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-black font-medium text-[14px] mb-2">
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        className="w-full border border-gray-200 rounded-md p-2 text-gray-600 text-[14px] outline-none focus:border-secondary"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="phone_number" className="block text-black font-medium text-[14px] mb-2">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="Enter phone number"
                                        value={formData.phone_number}
                                        onChange={handleChange}
                                        className="w-full border border-gray-200 rounded-md p-2 text-gray-600 text-[14px] outline-none focus:border-secondary"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-black font-medium text-[14px] mb-2">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full border border-gray-200 rounded-md p-2 text-gray-600 text-[14px] outline-none focus:border-secondary"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="project_id" className="block text-black font-medium text-[14px] mb-2">
                                        Select Project
                                    </label>
                                    <select
                                        id="project_id"
                                        name="project_id"
                                        value={formData.project_id}
                                        onChange={handleChange}
                                        className="w-full border border-gray-200 rounded-md p-2 text-gray-600 text-[14px] outline-none focus:border-secondary bg-transparent"
                                    >
                                        <option value="">Choose a project</option>
                                        {projects.map((project) => (
                                            <option key={project.id} value={project.id}>
                                                {project.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-secondary text-white py-2 rounded-md text-[14px] font-medium"
                                >
                                    Submit Referral
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Referral;
