import React, { useEffect, useState } from 'react';
import logo from '../assets/miles_logo.svg';
import { Link, useLocation } from 'react-router-dom';
import img from '../assets/land.png'
import { fetchProjects } from '../utils/api/api';
import toast from 'react-hot-toast';
import TestimonialSlider from '../components/TestimonialSlider';
import FAQSection from '../components/FAQSection';
import ContactUs from '../components/ContactUs';
import LandingFooter from '../components/LandiFooter';

const Index = () => {
  const [projects, setProjects] = useState([]);
  const location = useLocation();
  
  const getProjects = async() => {
    try {
      const response = await fetchProjects();
      setProjects(response.projects);
    } catch (error) {
      toast.error("Request failed");
    }
  }

  useEffect(() => {
    getProjects();
  }, [])

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path) =>
    location.pathname === path ? 'border-b-2 border-white font-medium text-white' : 'text-gray-100';

  return (
    <div className='tracking-wide'>
      <div id='#' className="tracking-wide flex px-[5%] w-full flex-col img-bg bg-gradient-to-b from-transparent to-black">
        <nav className="w-full bg-transparent py-4">
          <div className="flex justify-between items-center">
            <Link to="/">
              <img src={logo} alt="Logo" className="w-[60px]" />
            </Link>

            <div className="hidden md:flex items-center space-x-6 text-white">
              <Link to="/" className={`${isActive('/')}`}>
                Home
              </Link>
              <Link to="/projects" className={`${isActive('/projects')}`}>
                Featured Projects
              </Link>
              <Link to="/faqs" className={`${isActive('/faqs')}`}>
                FAQs
              </Link>
              <Link to="/contact" className={`${isActive('/contact')}`}>
                Contact Us
              </Link>
              <Link to="/accounts/login" className="border border-white rounded-md px-6 py-1.5">
                Login
              </Link>
              <Link to="/accounts/sign-up" className="bg-secondary text-white px-6 py-1.5 rounded-md">
                Sign Up
              </Link>
            </div>

            {/* Mobile Hamburger Icon */}
            <div className="md:hidden flex items-center">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
                  />
                </svg>
              </button>
            </div>
          </div>

          {menuOpen && (
            <div className="md:hidden mt-4 flex flex-col space-y-4 text-white">
              <Link to="/" className={`${isActive('/')}`} onClick={toggleMenu}>
                Home
              </Link>
              <Link to="/projects" className={`${isActive('/projects')}`} onClick={toggleMenu}>
                Featured Projects
              </Link>
              <Link to="/faqs" className={`${isActive('/faqs')}`} onClick={toggleMenu}>
                FAQs
              </Link>
              <Link to="/contact" className={`${isActive('/contact')}`} onClick={toggleMenu}>
                Contact Us
              </Link>
              <Link
                to="/accounts/login"
                className="border border-white rounded-md px-6 py-1.5"
                onClick={toggleMenu}
              >
                Login
              </Link>
              <Link
                to="/accounts/sign-up"
                className="bg-secondary text-white px-6 py-1.5 rounded-md"
                onClick={toggleMenu}
              >
                Sign Up
              </Link>
            </div>
          )}
        </nav>
        <div className="flex flex-col w-full md:w-1/2 my-auto">
          <p className="text-white font-semibold text-[30px] md:text-[54px] leading-tight">
            Find your perfect property with confidence and expert guidance
          </p>
          <p className="text-white text-[20px] font-medium my-4">Discover the perfect property with expert support every step on the way</p>
          <button className="bg-secondary text-white font-medium w-fit uppercase text-[14px] px-6 py-1.5 rounded-md">
            Sign up now
          </button>
        </div>
      </div>
      <div id="about" className="w-full justify-between flex py-8 flex-col md:flex-row px-[5%] gap-24 mt-6">
        <div className="flex w-full md:w-[50%] flex-col gap-4">
          <div className="flex items-center gap-3">
            <div className="w-[150px] bg-primary h-[2px]" />
            <p className="text-primary font-medium text-[13px]">Why choose us</p>
          </div>
          <p className="text-[28px] font-semibold ">Discover exceptional land for you next project</p>
          <p className="text-[15px] font-light text-black">
            We offer a diverse selection of prime land, perfect for a wide range of purposes, including residential, commercial, and developmental projects. Each plot in our portfolio has been carefully selected to provide you with the ideal foundation for your vision, whether you're planning to build a home, start a business, or develop a larger project. Our land options cater to various needs, from small parcels in growing communities to larger plots in prime locations, offering endless possibilities for investors and developers alike. Whatever your goals, we are here to help you find the perfect land to bring them to life.
          </p>
        </div>
        <div className="w-full md:w-[50%]">
          <img src={img} alt="" />
        </div>
      </div>
      <div id="projects" className="bg-gray-100 flex flex-col justify-center items-center gap-3 py-8 px-[5%]">
        <div className="w-[150px] bg-primary h-[2px]" />
        <p className="text-primary font-semibold text-[15px]">Featured projects</p>
        <p className="text-[28px] font-semibold -mt-4">Discover some of the outstanding some of our prime land selections.</p>
        <div className="flex flex-col md:flex-row items-center gap-4 mt-6 ">
          {projects.slice(0, 4).map((project) => (
            <div className="flex flex-col justify-between h-[250px]">
              <img className='h-[220px]' src={project?.images?.file || 'https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn'} alt="" />
              <p className="text-black font-medium text-[22px] mt-4">{project.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <TestimonialSlider />
      </div>
      <div id="faqs" className="flex bg-gray-100 ">
        <FAQSection />
      </div>
      <div id="contact" className="flex bg-white">
        <ContactUs />
      </div>
      <LandingFooter />
    </div>
  );
};

export default Index;
