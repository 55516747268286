import React, { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'
import logo from '../assets/miles_logo.svg'
import moment from 'moment'
import seal from '../assets/MILESCOOP_SEAL.webp'
import SignatureCanvas from 'react-signature-canvas';
import sign from '../assets/General_Signature_4.png'
import Modal from '../elements/Modal'
import { useParams } from 'react-router-dom'
import api from '../utils/api/axiosInstance'
import toast from 'react-hot-toast'

const OfferLetter = ({ contract }) => {
    const [loading, setLoading] = useState(false);
    const [signatures, setSignatures] = useState([]);
    const [signature, setSignature] = useState(null);
    const [name, setName] = useState('');
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const { id } = useParams();
    const sigCanvas = useRef({});

    useEffect(() => {
        if (contract.signature) {
            try {
                const existingSignatures = JSON.parse(contract.signature);
                setSignatures(existingSignatures);
            } catch (error) {
                console.error("Error parsing existing signatures", error);
            }
        }
    }, [contract.signature]);

    const clearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
        setName('');
    };

    const saveSignature = async () => {
        const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const updatedSignatures = [...signatures, { name, sign: dataURL }];

        const payload = {
            id,
            signature: contract.customer.is_group ? JSON.stringify(updatedSignatures) : dataURL,
        };
        setLoading(true);

        try {
            await api.post('/contracts/sign', payload);
            toast.success("Signatures saved successfully.");
            setSignatures(updatedSignatures);
            window.location.reload();
        } catch (error) {
            toast.error("Failed to save signatures");
        } finally {
            setLoading(false);
        }
        setIsSignModalOpen(false);
    };

    return (
        <div className="flex w-full overflow-x-auto font-tinos">
            <div className="min-w-[600px] w-full mx-auto p-8 bg-white shadow-md rounded-md">
                <div className="border-2 flex items-start justify-between border-primary p-4 mb-4 w-full ">
                    <div className="flex items-start gap-2">
                        <img src={logo} alt="Milescoop Ventures" className="w-[170px] h-auto" />
                        <div className="flex flex-col">
                            <p className="font-bold text-[20px] uppercase text-primary">Milescoop Ventures Ltd</p>
                            <p className="uppercase text-red-500 text-[13px]">Your ultimate real estate destination in Kenya</p>
                            <p className="text-black text-[14px]">Phone: +254 (0) 737 900 424</p>
                            <p className="text-black text-[14px]">Email: info@milescoop.com</p>
                            <p className="text-black text-[14px]">PIN Number: 052203487E</p>
                            <p className="text-black text-[14px]">Website: www.milescoop.com</p>
                        </div>
                    </div>
                    {contract.serial_no && (
                        <div className="flex justify-center mt-8">
                            <QRCode
                                value={contract?.serial_no}
                                size={100}
                                bgColor="#ffffff"
                                fgColor="#000000"
                            />
                        </div>
                    )}
                </div>
                <div className="flex items-center w-full justify-between">
                    <div className="mb-4">
                        <p className='text-gr text-[14px]'>
                            <span className="text-black w-[50px]">Date:</span> {moment(contract.start_date).format('MMM Do, YYYY')}
                        </p>
                        <div className="flex items-start gap-4 w-fit">
                            <p className="w-[50px]">To:</p>
                            <div className="flex flex-col">
                                <p className='text-black text-[13px] uppercase'>{contract?.customer?.first_name} {contract?.customer?.last_name}</p>
                                <p className='text-black text-[13px] uppercase'>P.O BOX:</p>
                            </div>
                        </div>
                        <p className="text-black text-[11px] mt-2">Dear <span className="uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</span>,</p>
                    </div>
                    {contract.serial_no && (
                        <Barcode
                            value={contract?.serial_no}
                            width={2}
                            height={100}
                            displayValue={true}
                            background="#ffffff"
                            lineColor="#000000"
                            margin={10}
                            fontOptions="monospace"
                            textAlign="center"
                        />
                    )}
                </div>
                <h2 className="text-lg font-bold mb-4 uppercase text-black underline">RE: LETTER OF OFFER TO PURCHASE</h2>
                <p className="mb-2 text-[14px]"><strong>{contract?.property?.title_number}</strong></p>
                <p className="mb-4 uppercase text-black font-medium text-[11px]">
                    Being a portion of Title Numbers: {contract?.property?.title_number} in the development known as "{contract?.order?.order_items[0]?.property?.project?.title}"
                </p>

                <div className="mb-6">
                    <p className="mb-4 text-[13px] text-black">
                        We are pleased to offer you the above-captioned property for purchase, subject to contract by way of a formal sale agreement prepared by the Vendor's Advocates. Below are the terms and conditions:
                    </p>

                    <div className="flex">
                        <h3 className="font-bold mb-2 w-[220px] text-[13px]">1. Vendor's Details:</h3>
                        <p className='text-[11px]'>Milescoop Ventures LTD, <br /> P.O. BOX 2760-00100, <br /> Nairobi</p>
                    </div>

                    <div className="flex mt-2">
                        <h3 className="font-bold mb-2 w-[220px] text-[13px]">2. Purchaser's Details</h3>
                        <div className="flex flex-col text-[11px]">
                            <p className='uppercase'>{contract?.customer?.first_name} {contract?.customer?.last_name}, <br /> P.O. BOX: <br /> Nairobi, Kenya</p>
                            <p>Email: {contract?.customer?.email}</p>
                        </div>
                    </div>

                    <div className="flex mt-2">
                        <h3 className="font-bold mb-2 w-[220px] text-[13px]">3. Vendor's Advocates</h3>
                        <div className="flex flex-col text-[11px]">
                            <p>M.W. KIMANI & CO. ADVOCATES,<br /> P.O. BOX: SURAJ PLAZA 6TH FLOOR, LIMURU ROAD, NGARA 61474-00200, <br /> Nairobi, Kenya</p>
                            <p>Email: mercymaina33@gmail.com</p>
                        </div>
                    </div>

                    <h3 className="font-bold text-[13px] mt-2">4. Development</h3>
                    <p className='text-[11px]'>{contract?.order?.order_items[0]?.property?.project?.description}.
                    </p>

                    <h3 className="font-bold mb-2 text-[13px]">5. The Property</h3>
                    {contract?.order?.order_items?.map((item, index) => (
                        <p key={item.id} className="text-[11px] text-black mb-2">
                            The property means PLOT-{item.property.property_number} measuring approximately
                            <span className="underline font-medium mx-1">{contract?.order?.order_items[0]?.property?.project?.size}</span> per plot being a portion of Title Number(s):
                            <span className="underline font-medium mx-1">{item.property.title_number}</span>.
                        </p>
                    ))}

                    <h3 className="font-bold mt-2 mb-2 text-[13px]">6. Tenure</h3>
                    <p className='text-[11px]'>Freehold</p>

                    <h3 className="font-bold mt-2 mb-2 text-[13px]">7. Purchase Offer Price</h3>
                    {contract?.order?.payment_type === 'one_time' ? (
                        <p className='text-[11px]'>The plot is offered for purchase at a purchase price of KES {Number(contract?.order.amount).toLocaleString("KES")}. <span className="ml-2">For cash buyers payment should be done in thirty days (30days).</span></p>
                    ) : (
                        <>
                            <p className='text-[11px]'>The plot is offered for purchase at a purchase price of KES {Number(contract?.order?.amount).toLocaleString("KES")}.</p>
                            <p className='text-[11px]'>The remaining amount will be paid over a period of the specified months as per the payment schedule below:</p>

                            <div className="overflow-x-auto">
                                <table className="table-auto w-full mt-2 border-collapse text-[11px]">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="border p-2 text-left">No</th>
                                            <th className="border p-2 text-left">Payment Amount</th>
                                            <th className="border p-2 text-left">Due Date</th>
                                            <th className="border p-2 text-left">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contract?.order?.installments?.map((installment, index) => (
                                            <tr key={index}>
                                                <td className="border p-2">{index + 1}</td>
                                                <td className="border p-2">KES {Number(installment?.amount).toLocaleString("KES")}</td>
                                                <td className="border p-2">{moment(installment?.invoice?.payment_due_date)?.format('MMM Do, YYYY')}</td>
                                                <td className="border p-2 capitalize">{installment?.invoice?.payment_status?.replace('_', ' ')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <p className="mt-2 text-[11px]">
                                All payments should be made in cleared funds, without deduction, set-off, or counterclaim, to the Vendor's bank account:
                            </p>
                            <ul className="list-disc ml-6 text-[12px]">
                                <li>Account Name: MILESCOOP VENTURES LTD</li>
                                <li>Bank: Prime Bank</li>
                                <li>Account Number: 3000198531</li>
                                <li>Branch: Riverside</li>
                                <li>Paybill Option: Business No: 4113235, Account Name: MILESCOOP VENTURES LIMITED</li>
                            </ul>
                        </>
                    )}

                    <h3 className="font-bold mt-2 mb-2 text-[13px]">8. Assignment</h3>
                    <p className='text-[11px]'>The Purchaser shall not assign the rights, interests, or obligations from this Offer without the Vendor's consent.</p>

                    <p className="mt-2 text-[13px]"><strong>9. Termination by the Purchaser:</strong></p>
                    <p className="text-[11px]">
                        In the event the Purchaser, after execution of this Letter of Offer and payment of the Deposit, is unable to fulfill the
                        Purchaser's obligations as contained herein and therefore opts to withdraw from this transaction, with no fault on the part
                        of the Vendor, the Purchaser will forfeit an amount equivalent to thirty per cent (30%) of the Purchase Price being the
                        agreed liquidated damages for breach of the Purchaser's obligations. The Refund will not be applicable to a purchaser who
                        is above 30 (Thirty Days) in arrears, the whole amount paid will be deemed as forfeited. The Vendor shall thereafter refund
                        any money that is over and above the forfeited amount, less all costs, fees, and expenses incurred by the Vendor in
                        pursuance of this Letter of Offer subject to the vendor getting a (replacement) new purchaser and payment made in full. If the
                        Agreement for Sale shall have been executed by the Purchaser, then forfeiture for non-completion will be as per the terms of
                        the Agreement for Sale. All communication (including termination) must be in writing either sent via the provided official
                        email address or a printed & signed copy delivered to the Vendor’s office.
                    </p>

                    <p className="mt-2 text-[13px]"><strong>10. Sale Agreement:</strong> <span className="text-[11px]">
                        A formal Agreement for Sale will incorporate the Law Society of Kenya Conditions of Sale (1989 Edition).</span></p>
                </div>
                <div className="">
                    <p className="text-[13px]">
                        <strong>
                            11. LEGAL COSTS:
                        </strong>
                    </p>
                    <p className="text-[11px]">
                        11.1. The Purchaser shall be responsible to the Vendor's Advocates for the legal fees on account of preparing the
                        Agreement herein, together with the Disbursements incidental to the registration of the Property in favor of the
                        Purchaser, being inclusive but not limited to the Stamp duty payable together with the registration fees payable at
                        the Lands office. The breakdown in respect of the Legal Costs shall be contained in the Agreement for Sale as well as
                        the mode of payment.
                    </p>
                    <p className="text-[11px]">
                        11.2. The purchaser who have not defaulted to pay instalment will not be subject to pay the legal charges and their
                        title deed will be transferred to them with no other costs apart from sale price.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-[13px]"><strong>12. CONFIDENTIALITY:</strong></p>
                    <p className="text-[11px]">
                        The terms of this transaction will remain confidential unless both parties agree to non-confidentiality in writing.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-[13px]">
                        <strong>
                            13. POSSESSION:
                        </strong>
                    </p>
                    <p className="text-[11px]">
                        The Purchaser shall be entitled to vacant possession of the Property subject to full payment of the Purchase Price and all other
                        costs and charges herein
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-[13px]">
                        <strong>
                            14. VALIDITY:
                        </strong>
                    </p>
                    <p className="text-[11px]">
                        This Letter of Offer will be valid for <strong className="">3 days</strong> from the date hereof, after which the Offer will be deemed to have lapsed and
                        automatically become null and void, and the Vendor shall be at liberty to offer the Unit to a third party.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-[13px]">
                        <strong>
                            15. CONFIRMATION:
                        </strong>
                    </p>
                    <p className="text-[11px]">
                        15.1. To signify your acceptance of the above terms and conditions, kindly sign/execute on the space provided below
                        and initial on all other pages and return two copies of the same to us within <strong>3 days</strong> after the date of this Letter of Offer,
                        together with a deposit slip confirming payment of the Deposit, within the stipulated timelines.
                    </p>
                    <p className="text-[11px]">
                        15.2. The Vendor hereby accepts and acknowledges that this Letter of Offer is a binding agreement between the parties,
                        and the formal Agreement for Sale when executed shall supersede this letter of offer.
                    </p>
                </div>
                {contract?.signature && (
                    <div className="mt-8 w-full flex items-center justify-between">
                        <div className="flex flex-col">
                            <p className="font-bold text-[14px]">Yours Faithfully,</p>
                            <div className="flex items-center gap-4">
                                <div className="flex flex-col">
                                    <p className="mt-6">PETER NJAGI ....</p>
                                    <p>Director</p>
                                </div>
                                <img src={sign} className='w-[100px] h-auto' alt="Signature" />
                            </div>
                        </div>
                        <img
                            src={seal}
                            className='h-auto w-[120px]'
                            alt=""
                            style={{ transform: 'rotate(-150deg)' }}
                        />
                    </div>
                )}

                <div className="mt-2">
                    <p>
                        I, <span className="uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</span>, confirm acceptance of the above terms and conditions.
                    </p>
                    <p className="mt-2">
                        {contract?.signed_date ? (
                            <span>DATE: {moment(contract.signed_date).format('MMM DD, YYYY')}</span>
                        ) : (
                            <span>DATE: ____________________</span>
                        )}
                    </p>
                    <div className="flex items-center">
                        SIGNATURE:
                        <div className="">
                            {!contract.customer?.is_group && contract.signature ? (
                                <>
                                    <p className="text-red-500 text-[14px] mb-2">You have 3 days to sign this document.</p>
                                    <button
                                        onClick={() => setIsSignModalOpen(true)}
                                        className="bg-secondary text-white px-6 py-1.5 font-medium text-[14px] rounded-md"
                                    >
                                        Sign Document
                                    </button>
                                </>
                            ) : (
                                <div className="border-t w-full border-gray-200 mt-2">
                                    {contract?.customer?.is_group ? (
                                        <div className="flex space-x-8">
                                            {(() => {
                                                try {
                                                    const parsedSignatures = JSON.parse(contract.signature);
                                                    return parsedSignatures.map((signData, index) => (
                                                        <div key={index} className="text-center">
                                                            <p>{signData.name}</p>
                                                            <img
                                                                src={signData.sign}
                                                                alt={`${signData.name}'s Signature`}
                                                                className="w-36 h-auto"
                                                            />
                                                        </div>
                                                    ));
                                                } catch (error) {
                                                    console.error("Failed to parse signatures:", error);
                                                    return <p>Error loading signatures.</p>;
                                                }
                                            })()}
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={contract.signature} alt="Signature" className="w-40 h-auto" />
                                            <button onClick={() => setIsSignModalOpen(true)} className="text-blue-500 underline">
                                                Edit
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {contract.customer?.is_group && (
                                <button
                                    onClick={() => setIsSignModalOpen(true)}
                                    className="mt-2 bg-secondary text-white px-4 py-1.5 font-medium text-[14px] rounded-md"
                                >
                                    Append Signature
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                {isSignModalOpen && (
                    <Modal title="Sign document" onClose={() => setIsSignModalOpen(false)}>
                        <div className="p-4">
                            {contract.customer.is_group && (
                                <>
                                    <h2 className="text-lg font-bold mb-4">Add Signature</h2>
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full p-2 mb-4 border rounded"
                                    />
                                </>
                            )}
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="blue"
                                canvasProps={{
                                    width: 500,
                                    height: 200,
                                    className: 'border rounded',
                                }}
                            />
                            <div className="flex mt-4">
                                <button
                                    onClick={clearSignature}
                                    className="px-4 py-2 mr-2 bg-gray-200 border rounded"
                                >
                                    Clear
                                </button>
                                <button
                                    onClick={saveSignature}
                                    className="px-4 py-2 bg-blue-500 text-white rounded"
                                >
                                    Save Signature
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default OfferLetter