import Cookies from 'js-cookie';

export const getAccessToken = () => {
    return Cookies.get('access_token');
};

export const setAccessToken = (token) => {
    Cookies.set('access_token', token, { expires: 1 });
};

export const removeAccessToken = () => {
    Cookies.remove('access_token');
    Cookies.remove('user_id');
    window.location.assign('/accounts/login');
};