import axios from 'axios';
import Cookies from 'js-cookie';

const BASEURL = 'https://api.milescoop.com/api/v1';
const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

const api = axios.create({
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': API_KEY,
  },
});

api.interceptors.request.use(
  config => {
    const token = Cookies.get('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
