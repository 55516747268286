import React, { useState } from 'react';
import {
    MdOutlineDashboard,
    MdAttachMoney,
    MdAssignment,
    MdMessage,
    MdSupportAgent,
    MdSettings,
    MdLogout,
    MdWorkOutline,
    MdPayment,
    MdReceipt,
    MdPersonAddAlt,
    MdEvent
} from 'react-icons/md';
import { LuTableProperties } from "react-icons/lu";
import { Link, useLocation, useParams } from 'react-router-dom';
import { removeAccessToken } from '../utils/api/authToken';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { FaRegCircleUser } from 'react-icons/fa6';
import logo from '../assets/miles_logo.svg'

const TopMenu = ({ header }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    const { id } = useParams();
    // const { user } = useContext(AuthContext);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const isActive = (paths) => paths.includes(location.pathname)
        ? 'bg-secondary text-primary'
        : 'text-white';

    return (
        <div>
            <div className="h-[7vh] flex items-center px-[3%] bg-white border-b border-gray-100">
                <div className="w-[18%]">
                    <Link to='/'>
                        <img src={logo} className="w-[50px] my-2" alt="Logo" />
                    </Link>
                </div>
                <div className="w-[94%] h-full pl-8 flex items-center justify-between">
                    <p className="text-white md:text-black font-medium text-[1px] md:text-[16px]">{header}</p>

                </div>
                <button onClick={toggleSidebar} className="md:hidden ml-4 text-2xl">
                    ☰
                </button>
            </div>

            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-10"
                    onClick={toggleSidebar}
                ></div>
            )}

            <div
                className={`fixed top-0 left-0 h-full bg-primary z-20 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } transition-transform duration-300 ease-in-out`}
                style={{ width: '70%' }}
            >
                <div className='bg-primary h-full w-full text-white p-6 flex-col overflow-y-auto'>
                    <p className="border-b mb-3 border-gray-500 text-white text-[15px] font-medium pb-2">General</p>

                    <Link to={`/dashboard`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard'])}`}>
                        <MdOutlineDashboard />
                        <span className='flex text-[14px]'>Dashboard</span>
                    </Link>

                    <Link to={`/dashboard/orders`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full  ${isActive(['/dashboard/orders', `/dashboard/orders/${id}/view`])}`}>
                        <MdAttachMoney />
                        <span className='flex text-[14px]'>Pay now</span>
                    </Link>

                    <Link to={`/dashboard/projects`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/projects', `/dashboard/projects/${id}/view`, `/dashboard/projects/${id}/payment`])}`}>
                        <MdWorkOutline />
                        <span className='flex text-[14px]'>Projects</span>
                    </Link>

                    <Link to={`/dashboard/my-properties`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-800 hover:text-white ${isActive(['/dashboard/my-properties'])}`}>
                        <LuTableProperties />
                        <span className='flex text-[14px]'>My Properties</span>
                    </Link>

                    <Link to={`/dashboard/contracts`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/contracts', `/dashboard/contracts/${id}/view-details`])}`}>
                        <MdAssignment />
                        <span className='flex text-[14px]'>Contracts</span>
                    </Link>

                    <Link to={`/dashboard/invoices`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/invoices', `/dashboard/invoices/${id}/view`])}`}>
                        <MdReceipt />
                        <span className='flex text-[14px]'>Invoices</span>
                    </Link>

                    <Link to={`/dashboard/payments`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full  ${isActive(['/dashboard/payments'])}`}>
                        <MdPayment />
                        <span className='flex text-[14px]'>My Statement</span>
                    </Link>                   

                    <Link to={`/dashboard/bookings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/bookings'])}`}>
                        <MdEvent />
                        <span className='flex text-[14px]'>Bookings</span>
                    </Link>

                    <Link to={`/dashboard/messaging`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/messaging'])}`}>
                        <MdMessage />
                        <span className='flex text-[14px]'>Messaging</span>
                    </Link>

                    <Link to={`/dashboard/support`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/support'])}`}>
                        <MdSupportAgent />
                        <span className='flex text-[14px]'>Support</span>
                    </Link>

                    <Link to={`/dashboard/referral`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/referral'])}`}>
                        <MdPersonAddAlt />
                        <span className='flex text-[14px]'>Referral</span>
                    </Link>

                    <Link to={`/dashboard/settings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard/settings'])}`}>
                        <MdSettings />
                        <span className='flex text-[14px]'>Settings</span>
                    </Link>

                    <button onClick={removeAccessToken} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px]  hover:bg-secondary hover:text-white ${isActive(['/dashboard/logout'])}`}>
                        <MdLogout />
                        <span className='flex text-[14px]'>Log Out</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TopMenu;
