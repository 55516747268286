import React, { useRef } from 'react';
import Slider from 'react-slick';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const ImageSliderModal = ({ images, onClose }) => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains('modal-background')) {
            onClose();
        }
    };

    const handleDownload = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    };

    const imageArray = Array.isArray(images) ? images : [images];

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 modal-background"
            onClick={handleBackgroundClick}
        >
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl relative">
                {imageArray.length === 1 ? (
                    <div className="relative">
                        <img src={imageArray[0].file} alt="Slide" className="mt-6 w-full h-auto object-cover" />
                        <button
                            onClick={() => handleDownload(imageArray[0].file, `image-${imageArray[0].id || 0}.jpg`)}
                            className="absolute top-4 right-4 px-3 py-1 text-white bg-blue-500 rounded-md"
                        >
                            Download
                        </button>
                    </div>
                ) : (
                    <Slider {...settings} ref={sliderRef}>
                        {imageArray.map((image, index) => (
                            <div key={index} className="relative">
                                <img src={image.file} alt={`Slide ${index}`} className="mt-6 w-full h-auto object-cover" />
                                <button
                                    onClick={() => handleDownload(image.file, `image-${index}.jpg`)}
                                    className="absolute top-4 right-4 px-3 py-1 text-white bg-blue-500 rounded-md"
                                >
                                    Download
                                </button>
                            </div>
                        ))}
                    </Slider>
                )}

                {imageArray.length > 1 && (
                    <>
                        <button
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-800"
                            onClick={() => sliderRef.current.slickPrev()}
                        >
                            <MdChevronLeft size={30} />
                        </button>
                        <button
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-800"
                            onClick={() => sliderRef.current.slickNext()}
                        >
                            <MdChevronRight size={30} />
                        </button>
                    </>
                )}

                <div onClick={onClose} className="cursor-pointer flex justify-end mt-4">
                    <button
                        className="px-6 py-1.5 text-white bg-secondary rounded-md text-[14px]"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImageSliderModal;
