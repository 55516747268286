import React from "react";
import Footer from "../components/Footer";
import LandingFooter from "../components/LandiFooter";

const TermsAndConditions = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <header className="bg-primary text-white py-6">
                <div className="container mx-auto px-4">
                    <h1 className="text-3xl font-bold">Terms and Conditions</h1>
                    <p className="text-sm mt-2">Effective Date: 2023</p>
                </div>
            </header>
            <main className="container mx-auto px-4 py-10">
                <section className="bg-white p-6 shadow-md rounded-md">
                    <h2 className="text-2xl font-bold mb-4">
                        Terms and Conditions for Milescoop Ventures Ltd
                    </h2>
                    <p className="text-gray-700 mb-6">
                        These Terms and Conditions ("Terms") govern your access to, use of, and engagement with the digital portal ("Portal") and services provided by Milescoop Ventures Ltd ("the Company," "we," "us," or "our"). By registering, signing up, purchasing, or otherwise interacting with the Portal, you agree to be bound by these Terms. Please read them carefully before proceeding.
                    </p>
                    <div className="space-y-6">
                        {/* Section 1 */}
                        <div>
                            <h3 className="text-xl font-semibold">1. User Registration and Account Creation</h3>
                            <p className="text-gray-700 mt-2">
                                <strong>1.1 Eligibility:</strong> You must be at least 18 years of age and have the legal capacity to form binding contracts under applicable law in order to use the Portal and its services. By using the Portal, you affirm that you meet these eligibility requirements.
                            </p>
                            <p className="text-gray-700 mt-2">
                                <strong>1.2 Account Information:</strong> To use certain features of the Portal, you must create an account by providing accurate, current, and complete information. You agree to update your account information as necessary to ensure its accuracy.
                            </p>
                            <p className="text-gray-700 mt-2">
                                <strong>1.3 Account Security and Responsibility:</strong> You are solely responsible for maintaining the confidentiality of your account credentials (e.g., username and password) and for all activities that occur under your account. If you suspect that your account has been compromised or unauthorized access has occurred, you must notify us immediately. We are not liable for any damages arising from authorised or unauthorized access to your account.
                            </p>
                        </div>

                        {/* Section 2 */}
                        <div>
                            <h3 className="text-xl font-semibold">2. Payment Terms</h3>
                            <p className="text-gray-700 mt-2">
                                <strong>2.1 Payment Process:</strong> All payments for services, projects, or digital content available through the Portal must be made in full at the time of purchase, unless otherwise stated.
                            </p>
                            <p className="text-gray-700 mt-2">
                                <strong>2.2 Accepted Payment Methods:</strong> Payments may be made using the available payment methods listed on the Portal, including but not limited to credit/debit cards, mobile money, and bank transfers. We reserve the right to modify accepted payment methods at our discretion.
                            </p>
                            <p className="text-gray-700 mt-2">
                                <strong>2.3 Late Payments:</strong> If any payment is not received within the specified payment period, we reserve the right to charge a late fee of 5% per month on the outstanding balance, which will accrue until the full amount is paid.
                            </p>
                        </div>

                        {/* Section 3 */}
                        <div>
                            <h3 className="text-xl font-semibold">3. Cancellations, Refunds, and Charges</h3>
                            <p className="text-gray-700 mt-2">
                                <strong>3.1 Cancellation Policy:</strong> If you wish to cancel any order or transaction, the following charges will apply:
                            </p>
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                <li>A cancellation fee of 30% of the total purchase value.</li>
                                <li>A processing fee of Ksh 500 for all cancellations.</li>
                                <li>Additional charges such as site visit fees, legal fees, and advertising costs as applicable.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <LandingFooter />
        </div>
    );
};

export default TermsAndConditions;
