import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { FaArrowUpFromBracket } from 'react-icons/fa6';
import { CiFilter } from "react-icons/ci";
import Cookies from 'js-cookie';
import { fetchCustomerProperties } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Modal from '../elements/Modal';

const MyProperties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const customer_id = Cookies.get('user_id');

    const getProperties = async () => {
        setLoading(true);
        try {
            const response = await fetchCustomerProperties(customer_id);
            setProperties(response.properties);
        } catch (error) {
            toast.error("Failed to get properties");
        } finally {
            setLoading(false);
        }
    };

    const openModal = (property) => {
        setSelectedProperty(property);
        setIsModalOpen(true); 
    };

    const closeModal = () => {
        setIsModalOpen(false); 
        setSelectedProperty(null)
    };

    useEffect(() => {
        getProperties();
    }, []);

    return (
        <Layout text="My Properties">
            {loading && <Loading />}
            <div className="flex w-full justify-between items-center mb-4">
                <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
                    <IoMdSearch className='text-gray-600' />
                    <input
                        type="text"
                        placeholder='Search'
                        className='outline-none border-none text-gray-600 font-light text-[14px]'
                    />
                </div>
                <div className="flex items-center gap-3">
                    <button className="border text-secondary text-[14px] font-medium items-center border-secondary px-4 py-1.5 rounded-md flex gap-2">
                        <CiFilter />
                        Filter
                    </button>
                    <button className="flex items-center gap-2 bg-secondary rounded-md px-4 py-1.5 text-white font-medium text-[14px]">
                        <FaArrowUpFromBracket />
                        Export
                    </button>
                </div>
            </div>

            <div className="w-full bg-white rounded-lg mt-4 p-4 ">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white h-full flex-grow rounded-lg table-auto">
                        <thead>
                            <tr className="text-primary border-b border-gray-400 pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title No.</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Size</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Property Number</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Price (KES)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {properties.length > 0 ? (
                                properties.map((property, index) => (
                                    <tr
                                        key={property.id}
                                        className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                        onClick={() => openModal(property)}
                                    >
                                        <td className="py-4 px-6 text-left whitespace-nowrap">{property.property?.title_number || 'N/A'}</td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">{property.property?.size || 'N/A'}</td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">{property.property?.status || 'N/A'}</td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">{property?.property?.property_number}</td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">{Number(property?.amount).toLocaleString("KES")}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="py-4 px-6 text-center text-gray-600 text-[14px]">
                                        No properties found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {selectedProperty && (
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    title="More Details"
                >
                    <div className="">

                        <div className="space-y-3 text-gray-700">
                            <p className="text-sm">
                                <span className="font-semibold">Property Number:</span> {selectedProperty.property?.property_number || 'N/A'}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Title Number:</span> {selectedProperty.property?.title_number || 'N/A'}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Size:</span> {selectedProperty.property?.size || 'N/A'}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Status:</span> {selectedProperty.property?.status || 'N/A'}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Amount:</span> Kes. {Number(selectedProperty.amount).toLocaleString("KES")}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Payment Type:</span> {selectedProperty.payment_type}
                            </p>
                            <p className="text-sm">
                                <span className="font-semibold">Payment Status:</span> {selectedProperty.payment_status}
                            </p>
                        </div>

                        <div className="flex justify-end mt-6">
                            <button
                                onClick={closeModal}
                                className="py-2 px-6 bg-secondary text-white font-medium rounded-md shadow-sm hover:bg-secondary-dark transition duration-300 ease-in-out"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default MyProperties;
