import React, { useState, useEffect, useRef } from 'react';
import Layout from '../elements/Layout';
import { MdOutlineAttachFile } from 'react-icons/md';
import { AiTwotoneAudio } from 'react-icons/ai';
import io from 'socket.io-client';
import Cookies from 'js-cookie'

const Messaging = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const customer_id = Cookies.get('user_id');
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io('https://api.milescoop.com');
        socketRef.current.on('connect', () => {
            console.log('Socket connected.');

            const registerPayload = {
                request_type: 'register',
                sender_type: 'customer',
                sender_id: `CU${customer_id}`,
            };
            socketRef.current.emit('message', registerPayload);

            const historyPayload = {
                request_type: 'get_history',
                sender_type: 'customer',
                sender_id: `CU${customer_id}`,
                receiver_id: '1',
            };
            socketRef.current.emit('message', historyPayload);
        });

        socketRef.current.on('message', (data) => {
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data);
                } catch (error) {
                    return;
                }
            }
            console.log(data);

            if (data && data.chats && Array.isArray(data.chats)) {
                const mappedMessages = data.chats.map(chat => ({
                    sender: chat.sender_type,
                    text: chat.message,
                    file: chat.file,
                    file_type: chat.file_type,
                    time: new Date(chat.createdAt).toLocaleTimeString(),
                }));

                setMessages((prevMessages) => [
                    ...prevMessages,
                    ...mappedMessages
                ]);
            } else if (data.request_type === 'send_text') {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        sender: data.sender_type,
                        text: data.message,
                        file: null,
                        file_type: null,
                        time: new Date().toLocaleTimeString(),
                    },
                ]);
            }
        });

        socketRef.current.on('disconnect', () => {
            console.log('Socket disconnected.');
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const handleSendMessage = (e) => {
        e.preventDefault();

        const messagePayload = {
            request_type: 'send_text',
            sender_type: 'customer',
            sender_id: `CU${customer_id}`,
            receiver_id: '1',
            message: newMessage,
        };

        socketRef.current.emit('message', messagePayload);
        console.log('Message sent:', messagePayload);

        setMessages([...messages, { sender: 'customer', text: newMessage, time: new Date().toLocaleTimeString() }]);
        setNewMessage('');
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(selectedFile);
            fileReader.onload = (e) => {
                const arrayBuffer = e.target.result;

                const filePayload = {
                    request_type: 'send_file',
                    sender_type: 'customer',
                    sender_id: `CU${customer_id}`,
                    receiver_id: '1',
                    file_type: selectedFile.type,
                    file_name: selectedFile.name,
                    file_data: arrayBuffer,
                };

                socketRef.current.emit('message', filePayload);
                console.log('File sent:', filePayload);

                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        sender: 'customer',
                        text: `Sent a file: ${selectedFile.name}`,
                        time: new Date().toLocaleTimeString(),
                    },
                ]);
            };

            fileReader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
        }
    };

    const handleRecordAudio = (audioData) => {
        const audioPayload = {
            request_type: 'send_audio',
            sender_type: 'customer',
            sender_id: `CU${customer_id}`,
            receiver_id: '1',
            file_type: 'audio/mp3',
            file_name: 'AudioRecording.mp3',
            file_data: audioData, 
        };

        socketRef.current.emit('message', audioPayload);
        console.log('Audio message sent:', audioPayload);

        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'customer', text: 'Sent an audio message', time: new Date().toLocaleTimeString() }
        ]);
    };

    return (
        <Layout text="Chat">
            <div className="flex flex-col h-full w-full max-w-4xl mx-auto bg-gray-50 shadow-lg rounded-md">
                <div className="flex-grow p-4 overflow-y-auto">
                    {messages.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-full text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
                                <rect width="64" height="64" rx="12" fill="#E5E7EB" />
                                <path d="M16 24h32v20c0 2.209-1.791 4-4 4H20c-2.209 0-4-1.791-4-4V24z" fill="#3B82F6" />
                                <path d="M20 20c-2.209 0-4 1.791-4 4v20c0 2.209 1.791 4 4 4h24c2.209 0 4-1.791 4-4V24c0-2.209-1.791-4-4-4H20z" stroke="#1E3A8A" stroke-width="2" />
                                <circle cx="32" cy="30" r="4" fill="#fff" />
                                <path d="M36 38a6 6 0 0 0-8 0" stroke="#fff" stroke-width="2" stroke-linecap="round" />
                                <rect x="20" y="16" width="24" height="4" rx="2" fill="#1E3A8A" />
                            </svg>
                            <p className="mt-4 text-lg">No messages yet</p>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`flex ${message.sender === 'customer' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`max-w-xs px-3 py-1 rounded-lg text-sm flex flex-col ${message.sender === 'customer'
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-300 text-gray-900'
                                            }`}
                                    >
                                        {message.file ? (
                                            message.file_type && message.file_type.startsWith('image/') ? (
                                                <div className="mb-2">
                                                    <img
                                                        src={message.file}
                                                        alt="uploaded"
                                                        className="w-full h-auto rounded-lg"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="mb-2">
                                                    <a
                                                        href={message.file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-300 underline"
                                                    >
                                                        Download File
                                                    </a>
                                                </div>
                                            )
                                        ) : message.text ? (
                                            <p>{message.text}</p>
                                        ) : null}
                                        <span className="text-xs text-end text-gray-200">{message.time}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <form
                    onSubmit={handleSendMessage}
                    className="p-3 bg-white flex items-center border-t space-x-3"
                >
                    <input
                        type="text"
                        className="flex-grow border border-gray-300 rounded-lg px-3 py-2 outline-none focus:border-blue-500"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <label className="flex items-center justify-center cursor-pointer bg-gray-200 rounded-lg px-3 py-2">
                        <input
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                        <MdOutlineAttachFile />
                    </label>
                    <button
                        type="button"
                        onClick={() => handleRecordAudio('your_audio_data_here')}
                        className="bg-gray-200 rounded-lg px-3 py-2"
                    >
                        <AiTwotoneAudio />
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                    >
                        Send
                    </button>
                </form>
            </div>
        </Layout>

    );
};

export default Messaging;
