import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Layout from './elements/Layout';
import Loading from './elements/Loading';
import { fetchCustomerById, updateCustomer } from './utils/api/api';

const EditInfo = () => {
    const customerId = Cookies.get('user_id');
    const [loading, setLoading] = useState(false);
    const [editCustomerData, setEditCustomerData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        id_number: '',
        dob: '',
        gender: '',
        nationality: '',
        city: '',
        state: '',
        country: '',
        next_of_kin1_name: '',
        next_of_kin1_phone: '',
        next_of_kin1_email: '',
        next_of_kin1_idNo: '',
        next_of_kin1_relationship: '',
        next_of_kin2_name: '',
        next_of_kin2_phone: '',
        next_of_kin2_email: '',
        next_of_kin2_idNo: '',
        next_of_kin2_relationship: '',
        photo: '',
        is_group: false,
        participants: '',
    });

    useEffect(() => {
        const getCustomer = async () => {
            try {
                const data = await fetchCustomerById(customerId);
                setEditCustomerData({ ...data.customer, photo: null });
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        getCustomer();
    }, [customerId]);

    const handleEditInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditCustomerData({
            ...editCustomerData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setEditCustomerData((prevData) => ({
            ...prevData,
            photo: file || null,
        }));
    };

    const handleCustomerFormSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        const fieldsToSend = [
            'id',
            'email',
            'first_name',
            'last_name',
            'phone_number',
            'id_number',
            'dob',
            'gender',
            'nationality',
            'city',
            'state',
            'country',
            'next_of_kin1_name',
            'next_of_kin1_phone',
            'next_of_kin1_email',
            'next_of_kin1_idNo',
            'next_of_kin1_relationship',
            'next_of_kin2_name',
            'next_of_kin2_phone',
            'next_of_kin2_email',
            'next_of_kin2_idNo',
            'next_of_kin2_relationship',
            'photo',
            'is_group',
            'participants',
        ];

        fieldsToSend.forEach((field) => {
            if (editCustomerData[field] !== undefined) {
                formData.append(field, editCustomerData[field]);
            }
        });

        for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        setLoading(true);
        try {
            const response = await updateCustomer(formData);
            toast.success('Info updated');
        } catch (error) {
            console.error('Update failed:', error);
            toast.error(error?.response?.data?.message || 'Failed to update');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout text="Update Customer">
            {loading && <Loading />}
            <form
                className='bg-white p-6 rounded-lg w-full md:w-[50%] mx-auto'
                onSubmit={handleCustomerFormSubmit}
            >
                <div className="flex items-center w-full gap-4">
                    <div className="w-full mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1" htmlFor="first_name">
                            First Name <span className='text-secondary'>*</span>
                        </label>
                        <input
                            id="first_name"
                            type="text"
                            name="first_name"
                            value={editCustomerData.first_name || ''}
                            onChange={handleEditInputChange}
                            className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                    <div className="w-full mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1" htmlFor="last_name">
                            Last Name <span className='text-secondary'>*</span>
                        </label>
                        <input
                            id="last_name"
                            type="text"
                            name="last_name"
                            value={editCustomerData.last_name || ''}
                            onChange={handleEditInputChange}
                            className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="email">
                        Email <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={editCustomerData.email || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="flex items-center w-full gap-4">
                    <div className="w-full mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1" htmlFor="phone_number">
                            Phone Number <span className='text-secondary'>*</span>
                        </label>
                        <input
                            id="phone_number"
                            type="text"
                            name="phone_number"
                            value={editCustomerData.phone_number || ''}
                            onChange={handleEditInputChange}
                            className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                    <div className="w-full mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1" htmlFor="id_number">
                            ID Number <span className='text-secondary'>*</span>
                        </label>
                        <input
                            id="id_number"
                            type="text"
                            name="id_number"
                            value={editCustomerData.id_number || ''}
                            onChange={handleEditInputChange}
                            className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="dob">
                        Date of Birth <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="dob"
                        type="date"
                        name="dob"
                        value={editCustomerData.dob || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="gender">
                        Gender <span className='text-secondary'>*</span>
                    </label>
                    <select
                        id="gender"
                        name="gender"
                        value={editCustomerData.gender || ''}
                        onChange={handleEditInputChange}
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="nationality">
                        Nationality <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="nationality"
                        type="text"
                        name="nationality"
                        value={editCustomerData.nationality || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="city">
                        City <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="city"
                        type="text"
                        name="city"
                        value={editCustomerData.city || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="state">
                        State <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="state"
                        type="text"
                        name="state"
                        value={editCustomerData.state || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="country">
                        Country <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="country"
                        type="text"
                        name="country"
                        value={editCustomerData.country || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>

                <h3 className="text-black text-[14px] font-medium mb-1">Next of Kin 1</h3>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin1_name">
                        Name <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="next_of_kin1_name"
                        type="text"
                        name="next_of_kin1_name"
                        value={editCustomerData.next_of_kin1_name || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin1_phone">
                        Phone <span className='text-secondary'>*</span>
                    </label>
                    <input
                        id="next_of_kin1_phone"
                        type="text"
                        name="next_of_kin1_phone"
                        value={editCustomerData.next_of_kin1_phone || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin1_email">
                        Email
                    </label>
                    <input
                        id="next_of_kin1_email"
                        type="email"
                        name="next_of_kin1_email"
                        value={editCustomerData.next_of_kin1_email || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin1_idNo">
                        ID Number
                    </label>
                    <input
                        id="next_of_kin1_idNo"
                        type="text"
                        name="next_of_kin1_idNo"
                        value={editCustomerData.next_of_kin1_idNo || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin1_relationship">
                        Relationship
                    </label>
                    <input
                        id="next_of_kin1_relationship"
                        type="text"
                        name="next_of_kin1_relationship"
                        value={editCustomerData.next_of_kin1_relationship || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>

                <h3 className="text-black text-[14px] font-medium mb-1">Next of Kin 2</h3>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin2_name">
                        Name
                    </label>
                    <input
                        id="next_of_kin2_name"
                        type="text"
                        name="next_of_kin2_name"
                        value={editCustomerData.next_of_kin2_name || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin2_phone">
                        Phone
                    </label>
                    <input
                        id="next_of_kin2_phone"
                        type="text"
                        name="next_of_kin2_phone"
                        value={editCustomerData.next_of_kin2_phone || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin2_email">
                        Email
                    </label>
                    <input
                        id="next_of_kin2_email"
                        type="email"
                        name="next_of_kin2_email"
                        value={editCustomerData.next_of_kin2_email || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin2_idNo">
                        ID Number
                    </label>
                    <input
                        id="next_of_kin2_idNo"
                        type="text"
                        name="next_of_kin2_idNo"
                        value={editCustomerData.next_of_kin2_idNo || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="next_of_kin2_relationship">
                        Relationship
                    </label>
                    <input
                        id="next_of_kin2_relationship"
                        type="text"
                        name="next_of_kin2_relationship"
                        value={editCustomerData.next_of_kin2_relationship || ''}
                        onChange={handleEditInputChange}
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="photo">
                        Photo
                    </label>
                    <input
                        id="photo"
                        type="file"
                        name="photo"
                        onChange={handleFileChange}
                        accept="image/*"
                        className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>

                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="is_group"
                            checked={editCustomerData.is_group}
                            onChange={handleEditInputChange}
                        />
                        Is Group
                    </label>
                </div>
                <div className='w-full mb-4 flex flex-col'>
                    <label className='block text-black text-[14px] font-medium mb-1'>
                        Participants:
                        <input
                            type="text"
                            name="participants"
                            className='border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary'
                            value={editCustomerData.participants}
                            onChange={handleEditInputChange}
                        />
                    </label>
                </div>

                <button
                    type="submit"
                    className="bg-secondary text-white font-medium px-6 py-1.5 text-[13px] rounded-lg"
                >
                    Update Customer
                </button>
            </form>
        </Layout>
    );
};

export default EditInfo;